import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { debounceTimes } from '@oper-client/shared/configuration';
import { faPlus, faMinus, IconDefinition } from '@oper-client/shared/util-fontawesome';
import { DestroyableComponent } from '@shared/util-component';

@Component({
	selector: 'oper-client-duration-selector-buttons',
	templateUrl: './duration-selector-buttons.component.html',
	styleUrls: ['./duration-selector-buttons.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationSelectorButtonsComponent extends DestroyableComponent implements OnInit {
	readonly iconPlus: IconDefinition = faPlus;
	readonly iconMinus: IconDefinition = faMinus;
	@Input() min = 0;
	@Input() max = 10;
	@Input() step = 1;
	@Input() value = 1;
	@Input() unit = 'year';
	@Output() changed = new EventEmitter<number>();

	private clicks$ = new Subject<number>();

	ngOnInit() {
		this.clicks$.pipe(debounceTime(debounceTimes.m), takeUntil(this.destroy$)).subscribe((payload) => this.changed.emit(payload));
	}

	decrease() {
		const oldValue = this.value;
		this.value -= this.step;
		if (this.value < this.min) {
			this.value = this.min;
		}
		if (oldValue !== this.value) {
			this.clicks$.next(this.value);
		}
	}

	increase() {
		const oldValue = this.value;
		this.value += this.step;
		if (this.value > this.max) {
			this.value = this.max;
		}
		if (oldValue !== this.value) {
			this.clicks$.next(this.value);
		}
	}
}
