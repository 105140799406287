import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { AmountRatioBarComponent } from '../amount-ratio-bar/amount-ratio-bar.component';

@Component({
	selector: 'oper-client-simulation-report-mortgage-overview',
	standalone: true,
	imports: [AmountRatioBarComponent],
	templateUrl: './simulation-report-mortgage-overview.component.html',
	styleUrl: './simulation-report-mortgage-overview.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportMortgageOverviewComponent {
	ownFunds = input<number>(0);
	amountToBorrow = input<number>(0);
}
