import { createFeature, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ActionState, Link, initialActionState, ActionTypes, setActionState } from '@oper-client/shared/data-model';
import * as LinkActions from './link.actions';
import { HttpErrorResponse } from '@angular/common/http';

export const LINK_ENTITY_KEY = 'links';

export interface LinkPartialState {
	readonly [LINK_ENTITY_KEY]: LinkState;
}

export type LinkActionTypes = 'loadLinks';
export type LinkActionsState = Record<LinkActionTypes, ActionState>;

export interface LinkState extends EntityState<Link> {
	actions: LinkActionsState;
}

export const linkAdapter: EntityAdapter<Link> = createEntityAdapter<Link>();

export const initialState: LinkState = linkAdapter.getInitialState({
	actions: {
		loadLinks: initialActionState,
	},
});

function setActionStates(
	actionState: LinkActionsState,
	action: LinkActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): LinkActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,

	on(LinkActions.loadLinks, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLinks', ActionTypes.loading),
	})),

	on(LinkActions.loadLinksSuccess, (state, { links }) =>
		linkAdapter.setAll(links, {
			...state,
			actions: setActionStates(state.actions, 'loadLinks', ActionTypes.success),
		})
	),
	on(LinkActions.loadLinksFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLinks', ActionTypes.failure),
	}))
);

export const linkFeature = createFeature({
	name: LINK_ENTITY_KEY,
	reducer,
});
