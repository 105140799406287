import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private _mobileNavigationShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private navigationEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	skipNavigationAnimations$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private readonly isDesktop: boolean = this.deviceDetectorService.isDesktop();

	constructor(private deviceDetectorService: DeviceDetectorService) {}

	set skipAnimations(value: boolean) {
		this.skipNavigationAnimations$.next(value && this.isDesktop);
	}

	public getMobileNavigationShown(): Observable<boolean> {
		return this._mobileNavigationShown$.asObservable();
	}

	public setMobileNavigationShown(isOpen: boolean): void {
		this._mobileNavigationShown$.next(isOpen);
	}

	public getNavigationEnabled(): Observable<boolean> {
		return this.navigationEnabled$.asObservable();
	}

	public setNavigationEnabled(isOpen: boolean): void {
		this.navigationEnabled$.next(isOpen);
	}
}
