import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { UtilFormattingModule } from '@oper-client/shared/util-formatting';

@Component({
	selector: 'oper-client-simulation-report-total-project-cost',
	standalone: true,
	imports: [UtilFormattingModule],
	templateUrl: './simulation-report-total-project-cost.component.html',
	styleUrl: './simulation-report-total-project-cost.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportTotalProjectCostComponent {
	totalProjectCost = input(0);
	totalPurchasePrice = input(0);
	totalCreditCosts = input(0);
	totalOtherCosts = input(0);
	totalPurchaseCosts = input<number | null>(null);
	totalDistributionCosts = input<number | null>(null);
	totalRenovationCosts = input<number | null>(null);
	totalRefinancingCosts = input<number | null>(null);
	$isExpanded = signal(false);
}
