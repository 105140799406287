import { registerLocaleData, CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDECH from '@angular/common/locales/de-CH';
import localeNLBE from '@angular/common/locales/nl-BE';
import localeENUK from '@angular/common/locales/en-GB';
import localeDEAT from '@angular/common/locales/de-AT';
import localeDEDE from '@angular/common/locales/de';
import localFRBE from '@angular/common/locales/fr-BE';
import localFRCH from '@angular/common/locales/fr-CH';
import localeITCH from '@angular/common/locales/it-CH';
import localeHUHU from '@angular/common/locales/hu';
import localeARAE from '@angular/common/locales/ar-AE';
import localeARSA from '@angular/common/locales/ar-SA';
import { ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { UiModule } from '@oper-client/ui';
import { DataAccessClientModule } from '@oper-client/shared/client/data-access-client';
import { ConnectionLostInterceptor, API_SERVICE, APPLICATION_FLOW_SERVICE } from '@oper-client/shared/data-access';

import { DataAccessDocumentModule } from '@oper-client/shared/document/data-access-document';
import { DataAccessIamModule } from '@oper-client/shared/iam/data-access-iam';
import { DataAccessLoanRequestModule } from '@oper-client/shared/loan-request/data-access-loan-request';
import { DataAccessRealtyModule } from '@oper-client/shared/realty/data-access';
import { DataAccessResourceModule } from '@oper-client/shared/resources/data-access-resource';
import { DataAccessApplicationFlowModule } from '@oper-client/shared/application-flow/data-access-application-flow';
import { DataAccessThirdPartyModule } from '@oper-client/shared/third-party/data-access-third-party';
import { SentryErrorHandler } from '@oper-client/shared/util-error-handling';
import { CaseStyleInterceptor, EnvironmentLocaleFormatService, UtilFormattingModule } from '@oper-client/shared/util-formatting';
import { gtmMetaReducer } from '@oper-client/shared/tracking/gtm';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import {
	MsalGuard,
	MsalInterceptor,
	MsalBroadcastService,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalGuardConfiguration,
} from '@azure/msal-angular';

import { MsaSsoAppRoutingModule } from './msa-sso-app-routing.module';
import { ApiV2Module, ApplicationFlowService, MsAuthService } from '@oper-client/shared/api-v2.0';
import {
	CUSTOMER_INSIGHTS_CONFIG,
	CustomerInsights,
	ENVIRONMENT,
	APP_INSIGHTS_CONFIG,
	APP_CONFIG,
	Configuration,
	CORE_RESOURCES_VERSION,
	CoreInformation,
	CORE_INFORMATION,
	DEFAULT_LOCALE,
	DEFINED_LANGUAGES,
	NUMBER_FORMAT_OPTIONS,
	DEFAULT_NUMBER_FORMAT_OPTIONS,
} from '@oper-client/shared/configuration';
import { DataAccessAppModule } from '@oper-client/shared/app/data-access-app';
import { DataAccessMortgageSimulatorModule } from '@oper-client/shared/mortgage-simulator/data-access-mortgage-simulator';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { CustomTranslateHttpLoader } from '@oper-client/shared/translation';

import { environment } from '../environments/environment';
import { MsaSsoAppComponent } from './msa-sso-app.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(config: CustomerInsights): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: config?.msAuth.clientId,
			authority: config?.msAuth.authority,
			redirectUri: config?.msAuth.redirectUri,
			postLogoutRedirectUri: config?.msAuth.postLogoutRedirectUri,
			navigateToLoginRequestUrl: false,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE, // set to true for IE 11
		},
	});
}

export function MSALInterceptorConfigFactory(config: CustomerInsights): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(`${config?.serverUrl}/resources/language/`, null);
	protectedResourceMap.set(`${config?.serverUrl}`, config?.msAuth.apiConfig.scopes);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(config: CustomerInsights): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: config?.msAuth?.apiConfig?.scopes ? [...config.msAuth.apiConfig.scopes] : [],
		},
	};
}

registerLocaleData(localeNLBE, 'nl-BE');
registerLocaleData(localeDECH, 'de-CH');
registerLocaleData(localeENUK, 'en-UK');
registerLocaleData(localeDEAT, 'de-AT');
registerLocaleData(localeDEDE, 'de-DE');
registerLocaleData(localFRBE, 'fr-BE');
registerLocaleData(localFRCH, 'fr-CH');
registerLocaleData(localeITCH, 'it-CH');
registerLocaleData(localeITCH, 'it-CH');
registerLocaleData(localeHUHU, 'hu-HU');
registerLocaleData(localeARAE, 'ar-AE');
registerLocaleData(localeARSA, 'ar-SA');

@NgModule({
	declarations: [MsaSsoAppComponent],
	bootstrap: [MsaSsoAppComponent],
	imports: [
		/* Angular */
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		MsaSsoAppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		MsalModule,
		ApiV2Module.forRoot({
			authService: MsAuthService,
			deps: [API_SERVICE, MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, EnvironmentLocaleFormatService],
		}),
		/* translations config */
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CustomTranslateHttpLoader,
				deps: [HttpClient, DEFAULT_LOCALE, DEFINED_LANGUAGES],
			},
			useDefaultLang: true,
			defaultLanguage: 'en-UK',
		}),
		/* Store configuration */
		StoreModule.forRoot({ routerReducer }, { metaReducers: [gtmMetaReducer] }),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
			connectInZone: true,
		}),
		/* Store modules imported eagerly */
		DataAccessAppModule,
		DataAccessIamModule,
		DataAccessResourceModule,
		DataAccessClientModule,
		DataAccessDocumentModule,
		DataAccessLoanRequestModule,
		DataAccessRealtyModule,
		DataAccessThirdPartyModule,
		DataAccessApplicationFlowModule,
		DataAccessMortgageSimulatorModule,
		/* Others */
		UiModule,
		UtilFormattingModule,
		StoreRouterConnectingModule.forRoot(),
	],
	providers: [
		/* Global providers */
		{ provide: ErrorHandler, useClass: SentryErrorHandler },
		{ provide: ENVIRONMENT, useValue: environment },
		{
			provide: APP_INSIGHTS_CONFIG,
			useFactory: (config: Configuration) => config.applicationInsights,
			deps: [APP_CONFIG],
		},
		{ provide: CUSTOMER_INSIGHTS_CONFIG, useFactory: (env: Configuration) => env.customerInsights, deps: [APP_CONFIG] },
		{ provide: LOCALE_ID, useFactory: (config: CustomerInsights) => config.locale, deps: [CUSTOMER_INSIGHTS_CONFIG] },
		{ provide: CORE_RESOURCES_VERSION, useFactory: (info: CoreInformation) => info.resources, deps: [CORE_INFORMATION] },
		[
			{
				provide: HTTP_INTERCEPTORS,
				useClass: CaseStyleInterceptor,
				multi: true,
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: ConnectionLostInterceptor,
				multi: true,
			},
		],
		/* API providers */
		{ provide: APPLICATION_FLOW_SERVICE, useClass: ApplicationFlowService, deps: [API_SERVICE] },
		/* AUTH providers */
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
			deps: [CUSTOMER_INSIGHTS_CONFIG],
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
			deps: [CUSTOMER_INSIGHTS_CONFIG],
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
			deps: [CUSTOMER_INSIGHTS_CONFIG],
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		{ provide: NUMBER_FORMAT_OPTIONS, useValue: { ...DEFAULT_NUMBER_FORMAT_OPTIONS, currencyDigitsInfo: '1.0-0' } },
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class MsaSsoAppModule {}
