<div class="menu">
	<ul class="list" *ngIf="items">
		<li *ngFor="let item of items" class="list-item" [class.list-item--danger]="item.type === 'danger'">
			<button class="list-button" (click)="handleItemClick(item)" [disabled]="item.disabled" [attr.data-test]="item.dataAttr">
				<oper-client-fontawesome-icon class="list-button__icon" *ngIf="item.icon" [icon]="item.icon"></oper-client-fontawesome-icon>
				<span class="list-button-label">{{ item.label | translate }}</span>
			</button>
		</li>
	</ul>
	<ng-container *ngFor="let group of groups">
		<div class="group-header">
			<span class="group-header__label">{{ group.label | translate }}</span>
			<a *ngIf="group.ctaLinkLabel" class="cta-button-link" (click)="handleGroupClick(group)">{{ group.ctaLinkLabel | translate }}</a>
		</div>
		<ul class="list" *ngIf="group.items">
			<li *ngFor="let item of group.items" class="list-item" [class.list-item--danger]="item.type === 'danger'">
				<button class="list-button" (click)="handleItemClick(item)" [disabled]="item.disabled" [attr.data-test]="'proofTable--contextMenuItem--' + item.name">
					<oper-client-fontawesome-icon
						*ngIf="item.icon"
						class="list-button__icon"
						[icon]="item.icon"
					></oper-client-fontawesome-icon>
					<span class="list-button-label">{{ item.label | translate }}</span>
				</button>
			</li>
		</ul>
	</ng-container>
</div>
