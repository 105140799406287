import { Component, ChangeDetectionStrategy, OnChanges, Input, SimpleChanges } from '@angular/core';
import { MortgageReportEntity } from '@oper-client/shared/data-model';
import { BehaviorSubject } from 'rxjs';

interface AprcConfigurationParam {
	fileCost: number;
	estimationCost: number;
	costRegistrationNotary: number;
	liabilityInsurance: number;
	liabilityInsuranceDuration: number;
	fireInsurance: number;
	fireInsuranceDuration: number;
}

@Component({
	selector: 'oper-client-mortgage-report-payment-aprc-tooltip',
	templateUrl: './mortgage-report-payment-aprc-tooltip.component.html',
	styleUrls: ['./mortgage-report-payment-aprc-tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MortgageReportPaymentAprcTooltipComponent implements OnChanges {
	aprcConfigurationParam$ = new BehaviorSubject<AprcConfigurationParam>(null);
	@Input() mortgageReport: MortgageReportEntity;
	@Input() tooltipContainer: Element;

	readonly digitsInfo = `1.0-0`;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['mortgageReport'] && this.mortgageReport?.configurationValues?.aprcCosts) {
			const liabilityInsurance = this.mortgageReport.configurationValues?.aprcInsurances?.find(
				(insurance) => insurance.name === 'liability'
			);
			const fireInsurance = this.mortgageReport.configurationValues?.aprcInsurances?.find((insurance) => insurance.name === 'fire');

			this.aprcConfigurationParam$.next({
				fileCost: this.mortgageReport.configurationValues?.aprcCosts?.fileCost,
				estimationCost: this.mortgageReport.configurationValues?.aprcCosts?.estimationCost,
				costRegistrationNotary:
					this.mortgageReport.configurationValues?.aprcCosts?.creditNotary +
					this.mortgageReport.configurationValues?.aprcCosts?.creditRegistration,
				liabilityInsurance: liabilityInsurance?.premium,
				liabilityInsuranceDuration: liabilityInsurance?.duration,
				fireInsurance: fireInsurance?.premium,
				fireInsuranceDuration: fireInsurance?.duration,
			});
		}
	}
}
