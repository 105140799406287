import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { BorrowerModes, Step, StepNames } from '@oper-client/shared/data-model';

export const setConfiguration = createAction(
	'[Application Flow] Set configuration',
	props<{ configuration: Step[]; forcedFirstStep: StepNames }>()
);
export const setBorrowerMode = createAction('[Application Flow] Set borrower mode', props<{ borrowerMode: BorrowerModes }>());

export const setActiveStep = createAction('[Application Flow] Set Active Step', props<{ step: Step }>());
export const nextStep = createAction('[Application Flow] Go to next Step');
export const prevStep = createAction('[Application Flow] Go to previous Step');

export const showLoadingIndicator = createAction('[Application Flow] Show loading indicator');
export const hideLoadingIndicator = createAction('[Application Flow] Hide loading indicator');

export const loadConfig = createAction('[Application Flow] Load Config');
export const loadConfigSuccess = createAction('[Application Flow] Load Config Success', props<{ config: any }>());
export const loadConfigFailure = createAction('[Application Flow] Load Config Failure', props<{ error: HttpErrorResponse }>());

export const reset = createAction('[Application Flow] Reset');

export const showError = createAction('[Application Flow] Show error', props<{ message: string }>());
export const hideError = createAction('[Application Flow] Hide error', props<{ message: string }>());

export const markStepForSkip = createAction('[Application Flow] Hide error', props<{ step: StepNames; skip: boolean }>());
