import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ExistingRegions } from '@oper-client/shared/configuration';
import { DurationCardPayload, MortgageReportEntity, Resource } from '@oper-client/shared/data-model';
import { faCalendarRange, IconDefinition } from '@oper-client/shared/util-fontawesome';
import { BehaviorSubject } from 'rxjs';
import { SimulationFlowType } from '@oper-client/shared/mortgage-simulator/data-access-mortgage-simulator';
import { DestroyableComponent } from '@shared/util-component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'oper-client-mortgage-report-offer-card',
	templateUrl: './mortgage-report-offer-card.component.html',
	styleUrls: ['./mortgage-report-offer-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MortgageReportOfferCardComponent extends DestroyableComponent implements OnChanges, OnInit, OnDestroy {
	static readonly lastClickedComponent$ = new BehaviorSubject<number>(-1);

	@Input() region: ExistingRegions;
	@Input() mortgageReport: MortgageReportEntity;
	@Input() durationInputFeature = false;
	@Input() simulationFlowType: Resource;
	@Input() componentKey: number;

	@Input()
	set mortgageReportProcessing(value: boolean) {
		this.mortgageReportProcessing$.next(value);
		if (!value) {
			MortgageReportOfferCardComponent.lastClickedComponent$.next(-1);
		}
	}

	@Input() parentContainer: Element;
	@Output() durationChangedCard = new EventEmitter<DurationCardPayload>();

	readonly ExistingRegionList = ExistingRegions;
	readonly iconCalendar: IconDefinition = faCalendarRange;
	readonly showDurationInput$ = new BehaviorSubject<boolean>(false);
	readonly lastClickComponentKey$ = new BehaviorSubject<number>(-1);
	readonly mortgageReportProcessing$ = new BehaviorSubject<boolean>(false);

	readonly offerTooltipText$ = new BehaviorSubject<string>(
		'ç.feature.selfService.steps.mortgageReport.payment.variabilityRates.variable'
	);

	ngOnInit(): void {
		if (this.mortgageReport?.variability?.definition === 'fix') {
			this.offerTooltipText$.next('ç.feature.selfService.steps.mortgageReport.payment.variabilityRates.fix');
		}
		MortgageReportOfferCardComponent.lastClickedComponent$.pipe(takeUntil(this.destroy$)).subscribe((key) => {
			this.lastClickComponentKey$.next(key);
		});
	}

	ngOnChanges() {
		const showDurationControl =
			this.mortgageReport.configurationValues.minDuration !== this.mortgageReport.configurationValues.maxDuration &&
			this.durationInputFeature &&
			this.simulationFlowType?.definition === SimulationFlowType.PRICE.definition;
		this.showDurationInput$.next(showDurationControl);
	}

	durationChanged(payload) {
		this.durationChangedCard.emit(payload);
		MortgageReportOfferCardComponent.lastClickedComponent$.next(payload.key);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.lastClickComponentKey$.complete();
		this.showDurationInput$.complete();
		this.lastClickComponentKey$.complete();
		this.mortgageReportProcessing$.complete();
		this.offerTooltipText$.complete();
	}
}
