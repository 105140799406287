/**
 * Get the computed style color from the root element
 * @param color The color name
 * @returns The color value as a string. If the color is not found, it will return an empty string
 * @example
 * ```ts
 * // Gets the computed color var(--color-primary)
 * const color = getComputedStyleColor('primary');
 * console.log(color); // #007bff
 * ```
 */
export function getComputedStyleColor(color: string): string {
	return getComputedStyleValue(`--color-${color}`);
}

export function getComputedStyleHeaderLogo(): string {
	return extractFilePath(getComputedStyleValue('--header-logo'));
}

export function getComputedStyleValue(value: string): string {
	return getComputedStyle(document.documentElement).getPropertyValue(value);
}

/**
 * Extracts the file path from a URL string.
 * @param input - The input string containing the URL.
 * @returns The extracted file path.
 */
function extractFilePath(input: string): string | null {
	const regex = /url\(['"]?([^'"]+)['"]?\)/;
	const match = input.match(regex);
	return match ? match[1] : null;
}
