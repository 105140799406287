import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ApplicationFlowActions from './application-flow.actions';
import { Step, StepNames } from '@oper-client/shared/data-model';

@Injectable()
export class ApplicationFlowEffects {
	setConfiguration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationFlowActions.setConfiguration),
			mergeMap(({ configuration, forcedFirstStep }) =>
				of(
					ApplicationFlowActions.setActiveStep({
						step: ApplicationFlowEffects.calculateFirstStep(configuration, forcedFirstStep),
					})
				)
			)
		)
	);

	static calculateFirstStep(configuration: Step[], forcedFirstStep: StepNames): Step {
		return typeof forcedFirstStep !== 'undefined' ? configuration.find(step => step.name === forcedFirstStep) : configuration[0];
	}

	constructor(private actions$: Actions, private store$: Store<any>) {}
}
