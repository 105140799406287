import { Proof } from '@oper-client/shared/data-model';

export const fetchTaxDeclarations = (proof: Proof[]) => {
	let taxDeclarations = [];
	for (const proofItem of proof) {
		if (proofItem.entity.category.definition !== 'loanRequest') {
			continue;
		}
		taxDeclarations = taxDeclarations.concat(
			...proofItem.documents
				.filter(doc => doc.documentType.definition === 'taxStatement')
				.map(document => ({ document, proofId: proofItem.id, uploaded: false }))
		);
	}
	return taxDeclarations.sort((a, b) => a.document.id - b.document.id);
};
