import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LanguageService } from '@oper-client/shared/util-language';
import { AUTH_SERVICE, IAuthService } from '@oper-client/shared/data-access';
import { IAM } from '@oper-client/shared/data-model';
import { AuthType } from '@oper-client/shared/configuration';
import { AppointmentBookingService } from '@oper-client/shared/api-v2.0';

@Component({
	selector: 'oper-client-self-service-root',
	styles: [':host { width: 100%; min-height: 100%;}'],

	changeDetection: ChangeDetectionStrategy.OnPush,
	template: ` <router-outlet /> `,
})
export class MsaSsoAppComponent {
	constructor(
		@Inject(AUTH_SERVICE) private readonly auth: IAuthService,
		private readonly languageService: LanguageService,
		private readonly appointmentBookingService: AppointmentBookingService
	) {
		this.languageService.init();
		this.appointmentBookingService.setBookingUrl();
		this.auth.getCurrentUser().subscribe((user: IAM.User) => {
			this.languageService.init(user, AuthType.AZURE_SSO);
		});
	}
}
