import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { BorrowerModes, Step, StepNames } from '@oper-client/shared/data-model';
import * as ApplicationFlowActions from './application-flow.actions';
import * as ApplicationFlowSelectors from './application-flow.selectors';

@Injectable()
export class ApplicationFlowFacade {
	constructor(private store: Store<any>) {}

	public configuration$ = this.store.pipe(select(ApplicationFlowSelectors.getConfiguration));

	public stepperConfiguration$ = this.store.pipe(
		select(ApplicationFlowSelectors.getConfiguration),
		map((steps) => steps?.filter((step) => !(step?.hideInStepper || step?.skip)))
	);

	public activeStep$ = this.store.pipe(select(ApplicationFlowSelectors.getActiveStep));

	public loadingIndicator$ = this.store.pipe(
		select(ApplicationFlowSelectors.getLoadingIndicator),
		filter((loadingIndicator) => loadingIndicator !== null)
	);

	public error$ = this.store.pipe(select(ApplicationFlowSelectors.getError));

	public enableSecondBorrower$ = this.store.pipe(
		select(ApplicationFlowSelectors.getEnableSecondBorrower),
		filter((enableSecondBorrower) => enableSecondBorrower !== null)
	);

	public borrowerMode$ = this.store.pipe(
		select(ApplicationFlowSelectors.getBorrowerMode),
		filter((borrowerMode) => borrowerMode !== null)
	);

	public setActiveStep(step: Step) {
		this.store.dispatch(ApplicationFlowActions.setActiveStep({ step }));
	}

	public setConfiguration(configuration: Step[], forcedFirstStep?: StepNames) {
		this.store.dispatch(ApplicationFlowActions.setConfiguration({ configuration, forcedFirstStep }));
	}

	public setBorrowerMode(borrowerMode: BorrowerModes) {
		this.store.dispatch(ApplicationFlowActions.setBorrowerMode({ borrowerMode }));
	}

	public showLoadingIndicator() {
		this.store.dispatch(ApplicationFlowActions.showLoadingIndicator());
	}

	public hideLoadingIndicator() {
		this.store.dispatch(ApplicationFlowActions.hideLoadingIndicator());
	}

	public nextStep() {
		this.store.dispatch(ApplicationFlowActions.nextStep());
	}

	public prevStep() {
		this.store.dispatch(ApplicationFlowActions.prevStep());
	}

	public showError(message: string) {
		this.store.dispatch(ApplicationFlowActions.showError({ message }));
	}

	public hideError() {
		this.store.dispatch(ApplicationFlowActions.hideError({ message: null }));
	}

	public reset() {
		this.store.dispatch(ApplicationFlowActions.reset());
	}

	public markStepForSkip(step: StepNames, skip: boolean) {
		this.store.dispatch(ApplicationFlowActions.markStepForSkip({ step, skip }));
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}
}
