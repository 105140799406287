/**
 * Mapper strategy interface to map between two types of objects from left to right and vice versa.
 *
 * For consistency suggested naming convention is from the left object to the right object.
 *
 * For example: `LoanApplicationDtoToLoanRequestMapper` maps from `LoanApplicationDto` to `LoanRequest`
 *
 * @param Left - The left object type
 * @param Right - The right object type
 */
export interface MapperStrategy<Left, Right> {
	/**
	 * Maps the left object in mapper strategy to the right object
	 */
	mapToRight(left: Left): Right;

	/**
	 * Maps the right object in mapper strategy to the left object
	 */
	mapToLeft(right: Right): Left;
}

/**
 * Maps the left object to the right object using the provided mapper strategy
 * @param left - The left object
 * @param mapper - The mapper to use
 */
export function mapToRight<Left, Right>(left: Left, mapper: MapperStrategy<Left, Right>): Right {
	return mapper.mapToRight(left);
}

/**
 * Maps the right object to the left object using the provided mapper strategy
 * @param right - The right object
 * @param mapper - The mapper to use
 */
export function mapToLeft<Left, Right>(right: Right, mapper: MapperStrategy<Left, Right>): Left {
	return mapper.mapToLeft(right);
}
