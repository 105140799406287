<div #target class="language-selector" [ngClass]="{ 'language-selector--inverted': inverted }">
	@if (collapsed) {
		<div class="current-container-small">
			<div class="current">{{ getCurrentLanguage() | translateResource: 'language' }}</div>
		</div>
	} @else {
		<div
			[ngClass]="{
				'language-selector-inner': true,
				'language-selector-inner--gray': isGray,
				'language-selector-inner--darken': !isMobile && !isTransparent,
				highlight
			}"
		>
			@if (showIcon) {
				<fa-icon class="language-icon" [icon]="languageIcon"></fa-icon>
			}
			<div class="current-container">
				<div class="current">{{ getCurrentLanguage() | translateResource: (isSortFormat ? 'language' : 'language-name') }}</div>
			</div>
			<div class="expand-container">
				<fa-icon class="expand-icon" [icon]="iconExpand" [ngClass]="{ 'expand-icon--expanded': expanded }"></fa-icon>
			</div>
		</div>
	}
</div>

<oper-client-context-menu
	[target]="target"
	[skipPositionList]="skipPositionList"
	[items]="items"
	(itemClicked)="changeLanguage($event)"
	(showEvent)="toggleExpanded()"
	(hideEvent)="toggleExpanded()"
></oper-client-context-menu>
