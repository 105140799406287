import { on } from '@ngrx/store';

import { ActionState, initialActionState, Step, BorrowerModes } from '@oper-client/shared/data-model';

import * as ApplicationFlowActions from './application-flow.actions';

export const APPLICATION_FLOW_KEY = 'applicationFlow';
import { createRehydrateReducer } from '@oper-client/shared/util-client-storage';

export interface ApplicationFlowState {
	configuration: Step[];
	activeStep: Step | null;
	borrowerMode: BorrowerModes;
	loadingIndicator: boolean;
	error: string | null;
	actions: {
		[key: string]: ActionState;
	};
}

export type ApplicationFlowActionTypes = 'loadConfig';
export type ApplicationFlowActionsState = Record<ApplicationFlowActionTypes, ActionState>;

export const initialState: ApplicationFlowState = {
	configuration: null,
	activeStep: null,
	borrowerMode: null,
	loadingIndicator: false,
	error: null,
	actions: {
		loadConfig: initialActionState,
	},
};

export const reducer = createRehydrateReducer(
	APPLICATION_FLOW_KEY,
	initialState,

	on(ApplicationFlowActions.setConfiguration, (state, { configuration }) => {
		return {
			...state,
			configuration,
		};
	}),

	on(ApplicationFlowActions.showLoadingIndicator, (state) => {
		return {
			...state,
			loadingIndicator: true,
		};
	}),

	on(ApplicationFlowActions.hideLoadingIndicator, (state) => {
		return {
			...state,
			loadingIndicator: false,
		};
	}),

	on(ApplicationFlowActions.setBorrowerMode, (state, { borrowerMode }) => {
		return {
			...state,
			borrowerMode,
		};
	}),

	on(ApplicationFlowActions.setActiveStep, (state, { step }) => {
		return {
			...state,
			activeStep: step,
		};
	}),

	on(ApplicationFlowActions.nextStep, (state) => {
		let nextStep = state.configuration.find((step) => step.name === state.activeStep.next);
		while (nextStep && nextStep?.skip) {
			nextStep = state.configuration.find((step) => step.name === nextStep.next);
		}
		return {
			...state,
			activeStep: nextStep,
		};
	}),

	on(ApplicationFlowActions.prevStep, (state) => {
		let previousStep = state.configuration.find((step) => step.name === state.activeStep.back);
		while (previousStep && previousStep?.skip) {
			previousStep = state.configuration.find((step) => step.name === previousStep.back);
		}
		return {
			...state,
			activeStep: previousStep,
		};
	}),

	on(ApplicationFlowActions.showError, (state, { message }) => {
		return {
			...state,
			error: message,
		};
	}),

	on(ApplicationFlowActions.hideError, (state) => {
		return {
			...state,
			error: null,
		};
	}),

	on(ApplicationFlowActions.reset, (state) => {
		return {
			...state,
			activeStep: null,
			borrowerMode: null,
		};
	}),

	on(ApplicationFlowActions.markStepForSkip, (state, { step, skip }) => {
		return {
			...state,
			configuration: state.configuration?.map((s) => (s.name === step ? { ...s, skip: !!skip } : s)),
		};
	})
);
