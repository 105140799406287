import { Step, StepNames } from '@oper-client/shared/data-model';
import { SimulationFlowType, SimulatorFlowConfig } from '../interface/simulator-flow-config';

const commonSteps = (backStep: StepNames, yearlyPeriodicity?: boolean) =>
	<Step[]>[
		{
			name: StepNames.MORTGAGE_OWN_FUNDS,
			next: StepNames.MORTGAGE_INCOME,
			back: backStep,
			title: 'ç.feature.selfService.steps.mortgageOwnFunds.title',
			description: 'ç.feature.selfService.steps.mortgageOwnFunds.description',
			disclaimer: false,
			nextLabel: 'ç.misc.action.continue',
			backLabel: 'ç.misc.action.back',
			required: true,
			recaulculateFormName: 'mortgageSimulatorOwnFunds',
		},
		{
			name: StepNames.MORTGAGE_INCOME,
			next: StepNames.MORTGAGE_LIABILITY,
			back: StepNames.MORTGAGE_OWN_FUNDS,
			title: 'ç.feature.selfService.steps.mortgageIncome.title',
			description: yearlyPeriodicity
				? 'ç.feature.selfService.steps.mortgageIncome.description.yearly'
				: 'ç.feature.selfService.steps.mortgageIncome.description.monthly',
			disclaimer: false,
			nextLabel: 'ç.misc.action.continue',
			backLabel: 'ç.misc.action.back',
			required: true,
			recaulculateFormName: 'mortgageSimulatorIncome',
		},
		{
			name: StepNames.MORTGAGE_LIABILITY,
			next: StepNames.MORTGAGE_REPORT,
			back: StepNames.MORTGAGE_INCOME,
			title: 'ç.feature.selfService.steps.mortgageLiability.title',
			description: yearlyPeriodicity
				? 'ç.feature.selfService.steps.mortgageLiability.description.yearly'
				: 'ç.feature.selfService.steps.mortgageLiability.description.monthly',
			disclaimer: false,
			nextLabel: 'ç.misc.action.continue',
			backLabel: 'ç.misc.action.back',
			required: true,
			recaulculateFormName: 'mortgageSimulatorLiability',
		},
		{
			name: StepNames.MORTGAGE_REPORT,
			next: null,
			back: StepNames.MORTGAGE_LIABILITY,
			title: 'ç.feature.selfService.steps.mortgageReport.title',
			description: 'ç.feature.selfService.steps.mortgageReport.description',
			disclaimer: 'ç.feature.selfService.steps.mortgageReport.disclaimer',
			nextLabel: 'ç.misc.action.next',
			backLabel: 'ç.misc.action.back',
			required: true,
			skipSimulation: true,
			hideInStepper: true,
		},
	];

export const austriaSimulatorConfig: (yearlyPeriodicity?: boolean) => SimulatorFlowConfig[] = (yearlyPeriodicity?: boolean) => [
	{
		label: 'ç.feature.selfService.steps.mortgageFlow.affordability.label',
		description: 'ç.feature.selfService.steps.mortgageFlow.affordability.description',
		simulationFlowType: SimulationFlowType.AFFORDABILITY,
		steps: [
			{
				name: StepNames.MORTGAGE_FLOW,
				next: StepNames.MORTGAGE_OWN_FUNDS,
				back: null,
				title: 'ç.feature.selfService.steps.mortgageFlow.title',
				description: 'ç.feature.selfService.steps.mortgageFlow.description',
				disclaimer: false,
				nextLabel: 'ç.misc.action.continue',
				backLabel: 'ç.misc.action.back',
				required: true,
				skipSimulation: true,
				hideInStepper: true,
			},
			...commonSteps(StepNames.MORTGAGE_FLOW, yearlyPeriodicity),
		],
	},
	{
		label: 'ç.feature.selfService.steps.mortgageFlow.price.label',
		description: 'ç.feature.selfService.steps.mortgageFlow.price.description',
		simulationFlowType: SimulationFlowType.PRICE,
		steps: [
			{
				name: StepNames.MORTGAGE_FLOW,
				next: StepNames.MORTGAGE_REALTY,
				back: null,
				title: 'ç.feature.selfService.steps.mortgageFlow.title',
				description: 'ç.feature.selfService.steps.mortgageFlow.description',
				disclaimer: false,
				nextLabel: 'ç.misc.action.continue',
				backLabel: 'ç.misc.action.back',
				required: true,
				skipSimulation: true,
				hideInStepper: true,
			},
			{
				name: StepNames.MORTGAGE_REALTY,
				next: StepNames.MORTGAGE_OWN_FUNDS,
				back: StepNames.MORTGAGE_FLOW,
				title: 'ç.feature.selfService.steps.mortgageProperty.title',
				description: 'ç.feature.selfService.steps.mortgageProperty.description',
				disclaimer: false,
				nextLabel: 'ç.misc.action.continue',
				backLabel: 'ç.misc.action.back',
				required: true,
				recaulculateFormName: 'mortgageSimulatorRealty',
			},
			...commonSteps(StepNames.MORTGAGE_REALTY, yearlyPeriodicity),
		],
	},
];
