import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of, switchMap } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import * as LoanApplicationActions from './loan-application.actions';
import {
	ADVISOR_SIMULATOR_SERVICE,
	CLIENT_SERVICE,
	IAdvisorSimulatorService,
	IClientService,
	ILoanRequestService,
	INCOME_SERVICE,
	IOfferService,
	LIABILITY_SERVICE,
	LOAN_REQUEST_SERVICE,
	LoanRequestEntityService,
	LoanRequestSubentityService,
	OFFER_SERVICE,
	PROPERTY_SERVICE,
} from '@oper-client/shared/data-access';
import { cancelPendingHttpRequests } from '@oper-client/shared/app/data-access-app';
import { Income, Liability, Realty } from '@oper-client/shared/data-model';

@Injectable()
export class LoanApplicationEffects {
	readonly loadLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadLoanRequest),
			switchMap(({ loanRequestId }) =>
				this.loanRequestService.getLoanRequest(loanRequestId).pipe(
					map((loanRequest) => LoanApplicationActions.loadLoanRequestSuccess({ loanRequest })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadLoanRequestFailure({ error })))
				)
			)
		)
	);

	readonly updateLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateLoanRequest),
			switchMap(({ update }) =>
				this.loanRequestService.updateLoanRequest(update.id as number, update.changes).pipe(
					map((loanRequest) => LoanApplicationActions.updateLoanRequestSuccess({ loanRequest })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateLoanRequestFailure({ error })))
				)
			)
		)
	);

	readonly loadSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadSimulation),
			switchMap(({ simulationId }) =>
				this.simulatorService.loadSimulation(simulationId).pipe(
					map((simulation) => LoanApplicationActions.loadSimulationSuccess({ simulation })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadSimulationFailure({ error })))
				)
			)
		)
	);

	readonly loadOffers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadOffers),
			switchMap(({ loanRequestId }) =>
				this.offerService.getAll(loanRequestId).pipe(
					map((offers) => LoanApplicationActions.loadOffersSuccess({ offers })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadOffersFailure({ error })))
				)
			)
		)
	);

	readonly loadClients$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadClients),
			switchMap(({ loanRequestId }) =>
				this.clientService.getAll(loanRequestId).pipe(
					map((clients) => LoanApplicationActions.loadClientsSuccess({ clients })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadClientsFailure({ error })))
				)
			)
		)
	);

	readonly loadRealties$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadRealties),
			switchMap(({ loanRequestId }) =>
				this.realtyService.getAll(loanRequestId).pipe(
					map((realties) => LoanApplicationActions.loadRealtiesSuccess({ realties })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadRealtiesFailure({ error })))
				)
			)
		)
	);

	readonly updateRealty$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateRealty),
			switchMap(({ loanRequestId, realty }) =>
				this.realtyService.update(loanRequestId, realty.id as number, realty.changes).pipe(
					map((realty) => LoanApplicationActions.updateRealtySuccess({ realty })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateRealtyFailure({ error })))
				)
			)
		)
	);

	readonly loadClientIncomes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadClientIncomes),
			switchMap(({ loanRequestId, clientId }) =>
				this.incomeService.getAll(loanRequestId, clientId).pipe(
					map((incomes) => LoanApplicationActions.loadClientIncomesSuccess({ incomes })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadClientIncomesFailure({ error })))
				)
			)
		)
	);

	readonly loadClientLiabilities$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadClientLiabilities),
			switchMap(({ loanRequestId, clientId }) =>
				this.liabilityService.getAll(loanRequestId, clientId).pipe(
					map((liabilities) => LoanApplicationActions.loadClientLiabilitiesSuccess({ liabilities })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadClientLiabilitiesFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		@Inject(LOAN_REQUEST_SERVICE) private readonly loanRequestService: ILoanRequestService,
		@Inject(OFFER_SERVICE) private readonly offerService: IOfferService,
		@Inject(CLIENT_SERVICE) private readonly clientService: IClientService,
		@Inject(PROPERTY_SERVICE) private realtyService: LoanRequestEntityService<Realty>,
		@Inject(INCOME_SERVICE) private readonly incomeService: LoanRequestSubentityService<Income>,
		@Inject(LIABILITY_SERVICE) private readonly liabilityService: LoanRequestSubentityService<Liability>,
		@Inject(ADVISOR_SIMULATOR_SERVICE) private readonly simulatorService: IAdvisorSimulatorService
	) {}
}
