import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { TopNavigationMenuItemDirective } from '../top-navigation-menu-item/top-navigation-menu-item.directive';
import { NavigationMenuItem } from '../../interfaces/navigation-menu-item';
import { getComputedStyleHeaderLogo } from '../../utils/style.utils';

@Component({
	selector: 'oper-client-top-navigation-menu',
	templateUrl: './top-navigation-menu.component.html',
	styleUrl: './top-navigation-menu.component.scss',
})
export class TopNavigationMenuComponent {
	readonly logoSrc = getComputedStyleHeaderLogo();
	@Input() isLogoClickable = false;
	@Input() logoRouterLink: string | null = null;
	@Output() logoClick = new EventEmitter<void>();
	@Output() hamburgerMenuIconClick = new EventEmitter<void>();
	@Output() menuItemClick = new EventEmitter<NavigationMenuItem>();
	@ContentChildren(TopNavigationMenuItemDirective) menuItems: QueryList<TopNavigationMenuItemDirective> | undefined;

	onMenuItemClick(item: TopNavigationMenuItemDirective): void {
		item.clicked.emit(item.getValue());
		this.menuItemClick.emit(item);
	}
}
