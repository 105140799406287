<div class="text">
	<p class="text__label">
		<span class="text__indicator"></span>
		{{ startLabel }}
	</p>
	<p class="text__value">
		{{ endLabel }}
		<span class="text__indicator text__indicator--inactive"></span>
	</p>
</div>
<div class="amount-container">
	<div class="used" [style.width.%]="calculateProgress()"></div>
</div>
<div class="currency">
	<div class="currency__label">
		{{ startValue | environmentCurrency }}
	</div>
	<div class="currency__value">
		{{ endValue | environmentCurrency }}
	</div>
</div>
