import { InjectionToken } from '@angular/core';
import { getCustomer, Configuration, CustomersEnum } from '@oper-client/shared/configuration';
import { ILoanApplicationFacade, LoanApplicationFeatureConfiguration } from '../interface/loan-application-feature.interface';
import defaultLoanApplicationConfiguration from '../config/loan-application/default-loan-application.configuration';
import medirectLoanApplicationConfiguration from '../config/loan-application/medirect-loan-application.configuration';

export const LOAN_APPLICATION_FEATURE_CONFIGURATION = new InjectionToken<LoanApplicationFeatureConfiguration>(
	'LoanApplicationFeatureConfiguration'
);

export const LOAN_APPLICATION_FACADE_SERVICE = new InjectionToken<ILoanApplicationFacade>('LoanApplicationFacadeService');

export function loanApplicationConfigurationFactory(
	customerConfig: Configuration,
	featureKey = 'loanApplication'
): LoanApplicationFeatureConfiguration {
	if (!customerConfig?.applicationInsights) {
		throw new Error('Customer configuration was not provided');
	}

	const featureConfiguration = customerConfig.featureConfigurations[featureKey] as LoanApplicationFeatureConfiguration;
	if (featureConfiguration) {
		return featureConfiguration;
	} else {
		const customer = getCustomer(customerConfig.applicationInsights) ?? CustomersEnum.BELGIAN;
		console.warn(
			`featureConfigurations.${featureKey} was not found in customer.json! Default '${customer}' configuration will be used instead.`
		);

		switch (customer) {
			case CustomersEnum.MEDIRECT:
				return medirectLoanApplicationConfiguration;
			default:
				return defaultLoanApplicationConfiguration;
		}
	}
}
