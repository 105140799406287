<div class="title">
	<h3>Total project cost</h3>
	<h3>{{ totalProjectCost() | environmentCurrency }}</h3>
</div>
@if ($isExpanded()) {
	<div class="details details--first">
		<p class="details__label">Purchase price</p>
		<p class="details__value">{{ totalPurchasePrice() | environmentCurrency }}</p>
	</div>

	@if (totalPurchaseCosts()) {
		<div class="details">
			<p class="details__label"><b>Purchase costs</b></p>
			<p class="details__value">
				<b>{{ totalPurchaseCosts() | environmentCurrency }}</b>
			</p>
		</div>
	}

	@if (totalDistributionCosts()) {
		<div class="details details--last">
			<p class="details__label"><b>Distribution costs</b></p>
			<p class="details__value">
				<b>{{ totalDistributionCosts() | environmentCurrency }}</b>
			</p>
		</div>
	}

	@if (totalRenovationCosts()) {
		<div class="details details--last">
			<p class="details__label"><b>Renovation costs</b></p>
			<p class="details__value">
				<b>{{ totalRenovationCosts() | environmentCurrency }}</b>
			</p>
		</div>
	}

	@if (totalRefinancingCosts()) {
		<div class="details details--last">
			<p class="details__label"><b>Refinancing costs</b></p>
			<p class="details__value">
				<b>{{ totalRefinancingCosts() | environmentCurrency }}</b>
			</p>
		</div>
	}

	<div class="details">
		<p class="details__label">Credit costs</p>
		<p class="details__value">{{ totalCreditCosts() | environmentCurrency }}</p>
	</div>

	<div class="details details--last">
		<p class="details__label">Other costs</p>
		<p class="details__value">{{ totalOtherCosts() | environmentCurrency }}</p>
	</div>
}
<p class="see-more" (click)="$isExpanded.set(!$isExpanded())">See {{ $isExpanded() ? 'less' : 'more' }}</p>
