<div class="summary">
	<h3 class="summary__title">{{ creditProvider() }}</h3>
	<div class="summary__pricing">
		<span class="summary__price">{{ monthlyAmortization() | environmentCurrency }}</span>
		<span class="summary__periodicity"> / month</span>
	</div>
	<p class="summary__details">{{ name() }}</p>
	<p class="summary__details">Interest rate: {{ interestRate().toFixed(2) + '%' }}</p>
	<p class="summary__details">APRC: {{ aprc().toFixed(2) + '%' }}</p>
</div>
<div class="actions">
	<oper-client-select
		[disabled]="true"
		class="select"
		value="1"
		[options]="yearOptions"
		[alreadySorted]="true"
		[searchable]="false"
		[clearable]="false"
		[placeholder]="'ç.question.defaultLabel' | translate"
		(valueChange)="onYearsChanged($event)"
	>
	</oper-client-select>
</div>
