import { Component } from '@angular/core';
import { MortgageReportOfferCardComponent } from '../mortgage-report-offer-card/mortgage-report-offer-card.component';

@Component({
	selector: 'oper-client-mortgage-report-offer-card-be',
	templateUrl: './mortgage-report-offer-card-be.component.html',
	styleUrls: ['./mortgage-report-offer-card-be.component.scss'],
})
export class MortgageReportOfferCardBeComponent extends MortgageReportOfferCardComponent {
	durationChangedBE(value: number) {
		this.durationChangedCard.emit({
			baseProduct: { id: this.mortgageReport.baseProduct.id },
			variability: { id: this.mortgageReport.variability.id },
			durationInput: value * 12,
			key: this.componentKey,
		});
	}
}
