import { createAction, props } from '@ngrx/store';
import { Client, Income, Liability, LoanRequest, Offer, Realty, Simulator, Step } from '@oper-client/shared/data-model';
import { LoanApplicationFeatureConfiguration } from '../../interface/loan-application-feature.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Update } from '@ngrx/entity';

export const setActiveStep = createAction('[LoanApplication] Set Active Step', props<{ step: Step }>());
export const updateActiveStep = createAction('[LoanApplication] Update Active Step', props<{ changes: Partial<Step> }>());
export const nextStep = createAction('[LoanApplication] Next Step');
export const prevStep = createAction('[LoanApplication] Previous Step');
export const reset = createAction('[LoanApplication] Reset');
export const clearData = createAction('[LoanApplication] Clear Data');
export const setData = createAction('[LoanApplication] Set Data', props<{ data: Partial<any> }>());
export const setDataForStep = createAction('[LoanApplication] Set Data For Step', props<{ step: string; data: Partial<any> }>());

export const setConfiguration = createAction(
	'[LoanApplication] Set Configuration',
	props<{ configuration: LoanApplicationFeatureConfiguration }>()
);
export const updateConfiguration = createAction(
	'[LoanApplication] Update Configuration',
	props<{ changes: Partial<LoanApplicationFeatureConfiguration> }>()
);
export const loadSimulation = createAction('[LoanApplication] Load Simulation', props<{ simulationId: number }>());
export const loadSimulationSuccess = createAction(
	'[LoanApplication] Load Simulation Success',
	props<{ simulation: Simulator.Simulation }>()
);
export const loadSimulationFailure = createAction('[LoanApplication] Load Simulation Failure', props<{ error: HttpErrorResponse }>());

export const loadLoanRequest = createAction('[LoanApplication] Load LoanRequest', props<{ loanRequestId: number }>());
export const loadLoanRequestSuccess = createAction(
	'[LoanApplication] Load LoanRequest Success',
	props<{ loanRequest: Partial<LoanRequest> }>()
);
export const loadLoanRequestFailure = createAction(
	'[LoanApplication] Load current LoanRequests Failure',
	props<{ error: HttpErrorResponse }>()
);

export const updateLoanRequest = createAction('[LoanApplication] Update LoanRequest', props<{ update: Update<LoanRequest> }>());
export const updateLoanRequestSuccess = createAction('[LoanApplication] Update LoanRequest Success', props<{ loanRequest: LoanRequest }>());
export const updateLoanRequestFailure = createAction('[LoanApplication] Update LoanRequest Failure', props<{ error: HttpErrorResponse }>());

export const loadOffers = createAction('[LoanApplication] Load Offers', props<{ loanRequestId: number }>());
export const loadOffersSuccess = createAction('[LoanApplication] Load Offers Success', props<{ offers: Offer[] }>());
export const loadOffersFailure = createAction('[LoanApplication] Load Offers Failure', props<{ error: HttpErrorResponse }>());

export const updateOffer = createAction('[Offer/API] Update Offer', props<{ loanRequestId: number; offer: any }>());
export const updateOfferSuccess = createAction('[Offer/API] Update Offer Success', props<{ offer: Update<Offer> }>());
export const updateOfferFailure = createAction('[Offer/API] Update Offer Failure', props<{ error: HttpErrorResponse; offer: any }>());

export const loadClients = createAction('[LoanApplication] Load Clients', props<{ loanRequestId: number }>());
export const loadClientsSuccess = createAction('[LoanApplication] Load Clients Success', props<{ clients: Client[] }>());
export const loadClientsFailure = createAction('[LoanApplication] Load Clients Failure', props<{ error: HttpErrorResponse }>());

export const updateClient = createAction('[LoanApplication Update Client', props<{ loanRequestId: number; client: Update<Client> }>());
export const updateClientSuccess = createAction('[LoanApplication Update Client Success', props<{ client: Update<Client> }>());
export const updateClientFailure = createAction('[LoanApplication Update Client Failure', props<{ error: HttpErrorResponse }>());

export const loadRealties = createAction('[LoanApplication] Load Realties', props<{ loanRequestId: number }>());
export const loadRealtiesSuccess = createAction('[LoanApplication] Load Realties Success', props<{ realties: Realty[] }>());
export const loadRealtiesFailure = createAction('[LoanApplication] Load Realties Failure', props<{ error: HttpErrorResponse }>());

export const updateRealty = createAction(
	'[LoanApplication] Update Realty',
	props<{ loanRequestId: number; realty: Update<Realty>; realtyMapping?: boolean }>()
);
export const updateRealtySuccess = createAction('[LoanApplication] Update Realty Success', props<{ realty: Realty }>());
export const updateRealtyFailure = createAction('[LoanApplication] Update Realty Failure', props<{ error: HttpErrorResponse }>());

export const loadClientIncomes = createAction(
	'[LoanApplication] Load Client Incomes',
	props<{ loanRequestId: number; clientId: number }>()
);
export const loadClientIncomesSuccess = createAction('[LoanApplication] Load Client Incomes Success', props<{ incomes: Income[] }>());
export const loadClientIncomesFailure = createAction(
	'[LoanApplication] Load Client Incomes Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadClientLiabilities = createAction(
	'[LoanApplication] Load Client Liabilities',
	props<{ loanRequestId: number; clientId: number }>()
);

export const loadClientLiabilitiesSuccess = createAction(
	'[LoanApplication] Load Client Liabilities Success',
	props<{ liabilities: Liability[] }>()
);

export const loadClientLiabilitiesFailure = createAction(
	'[LoanApplication] Load Client Liabilities Failure',
	props<{ error: HttpErrorResponse }>()
);

export function addClientIncome(arg0: {
	loanRequestId: number;
	clientId: number;
	income: Partial<import('@oper-client/shared/data-model').Income>;
}): any {
	throw new Error('Function not implemented.');
}

export function updateClientIncome(arg0: {
	loanRequestId: number;
	clientId: number;
	incomeId: number;
	income: Partial<import('@oper-client/shared/data-model').Income>;
}): any {
	throw new Error('Function not implemented.');
}

export function removeClientIncome(arg0: { loanRequestId: number; clientId: number; incomeId: number }): any {
	throw new Error('Function not implemented.');
}

export function addClientExpense(arg0: {
	loanRequestId: number;
	clientId: number;
	expense: Partial<import('@oper-client/shared/data-model').Income>;
}): any {
	throw new Error('Function not implemented.');
}

export function updateClientExpense(arg0: {
	loanRequestId: number;
	clientId: number;
	expenseId: number;
	expense: Partial<import('@oper-client/shared/data-model').Income>;
}): any {
	throw new Error('Function not implemented.');
}

export function removeClientExpense(arg0: { loanRequestId: number; clientId: number; expenseId: number }): any {
	throw new Error('Function not implemented.');
}
