/// <reference types="@types/google.maps" />

import { Component, OnInit, OnDestroy, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef, Inject, Input } from '@angular/core';
import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { DestroyableComponent } from '@shared/util-component';
import { takeUntil } from 'rxjs/operators';
import { GoogleLibrariesService } from '../../services/google-libraries.service';

@Component({
	selector: 'oper-client-address-autocomplete',
	styleUrls: ['./address-autocomplete.component.scss'],
	template: `
			<div class="form">
				<div class="label">{{ 'ç.feature.addressAutocomplete.searchForYourAdress' | translate }}</div>
				<div class="input-container">
					<oper-client-fontawesome-icon icon="faMagnifyingGlass" size="sm" [fixedWidth]="true"/>
					<input
						#address
						type="text"
						name="address"
						[placeholder]="'ç.feature.addressAutocomplete.placeholder' | translate"
						[disabled]="(googleAutocompleteReady$ | async) === false"
						(focus)="onFocus()"
						(blur)="onBlur()"
					/>
				</div>
			</div>
	`,
})
export class AddressAutocompleteComponent extends DestroyableComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('address') addressStringInputElement: ElementRef;

	@Input() countryRestrictions: string[] = [];
	@Output() addressSelected = new EventEmitter<any>();
	@Output() focused = new EventEmitter<void>();
	@Output() blurred = new EventEmitter<void>();

	googleAutocompleteReady$ = this.googleAddressAutocompleteService.scriptLoaded();

	private mainContainerElement: HTMLElement;
	private mainContainerElementListener: EventListener;
	private googleAutocomplete: google.maps.places.Autocomplete;
	private googleAutocompleteListener: google.maps.MapsEventListener;
	private autocompleteConfig: google.maps.places.AutocompleteOptions;

	constructor(
		@Inject(CUSTOMER_INSIGHTS_CONFIG) readonly customerConfig: CustomerInsights,
		private googleAddressAutocompleteService: GoogleLibrariesService
	) {
		super();
	}

	ngOnInit() {
		this.autocompleteConfig = {
			componentRestrictions: {
				country: this.countryRestrictions,
			},
			types: ['geocode'],
		};
	}

	ngAfterViewInit() {
		this.googleAutocompleteReady$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.initAddressAutocomplete();
		});
	}

	ngOnDestroy() {
		super.ngOnDestroy();

		if (this.mainContainerElement) {
			this.mainContainerElement.removeEventListener('scroll', this.mainContainerElementListener);
		}

		if (this.googleAutocomplete) {
			google.maps.event.removeListener(this.googleAutocompleteListener);
			google.maps.event.clearInstanceListeners(this.googleAutocomplete);
		}
		
		document.querySelector('.pac-container')?.remove();
	}

	private initAddressAutocomplete(): void {
		this.googleAutocomplete = new google.maps.places.Autocomplete(
			this.addressStringInputElement.nativeElement,
			this.autocompleteConfig
		);

		this.googleAutocompleteListener = google.maps.event.addListener(this.googleAutocomplete, 'place_changed', () => {
			const place = this.googleAutocomplete.getPlace();
			if (Object.keys(place).length > 1) {
				this.addressSelected.emit(place);
				this.addressStringInputElement.nativeElement.value = '';
			}
		});
	}

	onFocus(): void {
		const autocompleteContainer = document.querySelectorAll('.pac-container');
		autocompleteContainer.forEach(
			(el: HTMLElement) => (el.style.maxWidth = this.addressStringInputElement.nativeElement.getBoundingClientRect().width + 'px')
		);

		this.focused.emit();
	}
	onBlur(): void {
		this.addressStringInputElement.nativeElement.value = '';
		this.blurred.emit();
	}
}
