import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

declare const COMMIT_HASH: string;

@Injectable({
	providedIn: 'root',
})
export class CustomTranslateHttpLoader extends TranslateHttpLoader {
	defaultLocale: string;
	definedLanguages: Array<string>;

	constructor(http: HttpClient, defaultLanguage: string, definedLanguages: Array<string>) {
		super(http, `./assets/${COMMIT_HASH}/i18n/`, '.json');
		this.defaultLocale = defaultLanguage;
		this.definedLanguages = definedLanguages;
	}

	getTranslation(lang: string): Observable<any> {
		if (!this.definedLanguages.includes(lang)) {
			return super.getTranslation(this.defaultLocale);
		}
		return super.getTranslation(lang).pipe(
			catchError(() => {
				return super.getTranslation(this.defaultLocale);
			})
		);
	}
}
