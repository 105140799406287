<div class="container">
	<div
		class="header"
		(click)="handleCollapse()"
		[ngClass]="{ 'disabled': disabled, 'cursor-pointer': !toggleCollapseOnlyOnArrowClick, 'offset-background': offsetBackground }"
	>
		<ng-container [ngTemplateOutlet]="header?.templateRef"></ng-container>
		@if (header?.showArrow) {
			<fa-icon class="icon" [ngClass]="{ rotate: open }" [icon]="angleDownIcon" [fixedWidth]="true" (click)="arrowClick()"></fa-icon>
		}
	</div>
	<div class="content" [@openClose]="open ? 'open' : 'closed'" (click)="contentClicked.emit()">
		<ng-container [ngTemplateOutlet]="content?.templateRef"></ng-container>
	</div>
</div>
