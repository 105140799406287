import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@oper-client/shared/data-access';
import { ResourceResolverService } from '@oper-client/shared/util-resource-resolver';
import { FeatureFlag, ResourceType } from '@oper-client/shared/data-model';
import { FeatureFlagEnabledGuard } from '@oper-client/shared/util-feature-flag';

export const resourcesToLoad: { kinds: Array<ResourceType> } = {
	kinds: [ResourceType.LANGUAGE],
};

const simulatorResourcesToLoad: { kinds: Array<ResourceType> } = {
	kinds: [
		ResourceType.LANGUAGE,
		ResourceType.HOME_TYPE,
		ResourceType.CO_LIVING_GROUP_TYPE,
		ResourceType.CO_LIVING_PURPOSE,
		ResourceType.REGION,
		ResourceType.LOAN_REQUEST_PURPOSE,
	],
};

const simulatorNewFlowResourcesToLoad: { kinds: Array<ResourceType> } = {
	kinds: [
		ResourceType.LANGUAGE,
		ResourceType.LOAN_REQUEST_PURPOSE,
		ResourceType.REGION,
		ResourceType.PURCHASE_SALE_TYPE,
		ResourceType.LAND_PURCHASE_TYPE,
		ResourceType.LIABILITY_TYPE,
		ResourceType.INCOME_TYPE,
		ResourceType.REALTY_USAGE_TYPE,
		ResourceType.REALTY_TYPE,
		ResourceType.RENOVATION_TYPE,
	],
};

const routes: Routes = [
	{ path: '', redirectTo: 'app', pathMatch: 'full' },
	{
		path: 'auth',
		loadChildren: () => import('@oper-client/self-service/feature-auth').then((m) => m.SelfServiceFeatureAuthModule),
		resolve: { data: ResourceResolverService },
		data: { kinds: resourcesToLoad },
	},
	{
		path: 'app',
		loadChildren: () => import('@oper-client/self-service/feature-shell').then((m) => m.FeatureShellModule),
		canActivate: [AuthenticationGuard],
		canActivateChild: [AuthenticationGuard],
	},
	{
		path: 'start',
		loadChildren: () => import('@oper-client/self-service/feature-start').then((m) => m.FeatureStartModule),
		resolve: { data: ResourceResolverService },
		data: { kinds: resourcesToLoad },
	},
	{
		path: 'mortgage-simulator',
		canLoad: [FeatureFlagEnabledGuard],
		loadChildren: () => import('@oper-client/self-service/feature-mortgage-simulator').then((m) => m.FeatureMortgageSimulatorModule),
		resolve: { data: ResourceResolverService },
		data: { kinds: simulatorResourcesToLoad, flags: [FeatureFlag['SELF_SERVICE_MORTGAGE_SIMULATOR']], redirectTo: `app` },
	},
	{
		path: 'simulation',
		canLoad: [FeatureFlagEnabledGuard],
		loadChildren: () =>
			import('@oper-client/self-service/feature-mortgage-simulator').then((m) => m.FeatureMortgageSimulatorBelgiumNewFlowModule),
		resolve: { data: ResourceResolverService },
		data: {
			kinds: simulatorNewFlowResourcesToLoad,
			flags: [FeatureFlag['SELF_SERVICE_BORROWER_SIMULATOR_BELGIUM_NEW_VERSION']],
			redirectTo: `app`,
		},
	},
	{
		path: 'contact',
		loadChildren: () => import('@oper-client/self-service/feature-contact').then((m) => m.FeatureContactModule),
		resolve: { data: ResourceResolverService },
		data: { kinds: resourcesToLoad },
	},
	{
		path: '**',
		redirectTo: 'app',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
