import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class GoogleLibrariesService {
	scriptLoaded$ = new BehaviorSubject(false);
	placesService: google.maps.places.PlacesService;
	streetViewService: google.maps.StreetViewService;
	private scriptInjected = false;

	constructor(
		@Inject(CUSTOMER_INSIGHTS_CONFIG) readonly customerConfig: CustomerInsights,
		@Inject(DOCUMENT) private readonly document: Document
	) {}

	scriptLoaded(): Observable<boolean> {
		return this.scriptLoaded$.asObservable().pipe(
			filter((scriptLoaded) => scriptLoaded),
			take(1)
		);
	}

	injectLibrariesScript(): void {
		if (!this.scriptInjected) {
			const scriptElement = this.document.createElement('script');
			scriptElement.type = `application/javascript`;
			scriptElement.async = true;
			scriptElement.src = `https://maps.googleapis.com/maps/api/js?libraries=places,maps,marker&key=${this.customerConfig.googleApiKey}`;
			scriptElement.addEventListener('load', () => {
				this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
				this.streetViewService = new google.maps.StreetViewService();
				this.scriptLoaded$.next(true);
			});

			this.scriptInjected = true;
			this.document.body.insertAdjacentElement('beforeend', scriptElement);
		}
	}
}
