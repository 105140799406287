import { BorrowerSimulationDto } from '../dto/borrower-simulation.dto';
import { Client } from '../models/client.model';
import { Simulator } from '../models/simulator.models';
import { FinancialPlanCalculator } from '../utils/financial-plan.utils';
import { MapperStrategy } from '../utils/mapper.utils';

export class SimulationToBorrowerSimulationDtoMapper
	implements MapperStrategy<Partial<Simulator.Simulation>, Partial<BorrowerSimulationDto>>
{
	mapToRight(left: Partial<Simulator.Simulation>): Partial<BorrowerSimulationDto> {
		const financialPlanCalculator = new FinancialPlanCalculator(left.financialPlanLoanAmountItems, left.financialPlanOwnFundItems);
		return {
			realtyType: left.realtyType,
			region: left.region,
			realtyUsageType: left.realtyUsageType,
			epcBeforeRenovations: left.epcBeforeRenovations,
			epcAfterRenovations: left.epcAfterRenovations,
			priceBuilding: left.buildingCosts,
			purchaseSaleType: left.purchaseSaleType,
			isBuyingLand: left.priceOfLand > 0,
			priceOfLand: left.priceOfLand,
			buyOutAmount: left.buyOutAmount,
			realtyPrice: left.realtyPrice,
			venalValueBefore: left.venalValueBefore,
			architectFees: left.architectFees,
			landPurchaseType: left.landPurchaseType,
			loanPurpose: left.loanPurposes?.[0],
			duration: left.monthlyPaymentEstimations?.[0]?.duration,
			interestRateOnLoan: left.monthlyPaymentEstimations?.[0]?.rate,
			ownFunds: financialPlanCalculator.totalOwnFundsValue,
			totalLoanAmountAtBeginning: financialPlanCalculator.totalLoanAmountValue,
			renovationCost: left.renovationCosts?.[0],
			mainBorrowerIncomes: left.borrowersInfo?.[0]?.incomes,
			mainBorrowerLiabilities: left.borrowersInfo?.[0]?.liabilities,
			mainBorrowerPersonalDetails: left.borrowersInfo?.[0],
			coBorrowerIncomes: left.borrowersInfo?.[1]?.incomes,
			coBorrowerLiabilities: left.borrowersInfo?.[1]?.liabilities,
			coBorrowerPersonalDetails: left.borrowersInfo?.[1],
		};
	}

	mapToLeft(right: Partial<BorrowerSimulationDto>): Partial<Simulator.Simulation> {
		const calculator = new FinancialPlanCalculator(right.financialPlanLoanAmountItems, right.financialPlanOwnFundItems);
		const { financialPlanLoanAmountItems, financialPlanOwnFundItems } = calculator.updateFinancialPlan({
			key: 'savings', // TODO: get the key dynamically from the financial plan configuration
			amount: right.ownFunds,
		});

		return {
			name: `#${new Date().getTime()}`,
			realtyPrice: right.realtyPrice,
			region: right.region,
			loanPurposes: [right.loanPurpose],
			priceOfLand: right.priceOfLand,
			venalValueBefore: right.venalValueBefore,
			buildingCosts: right.priceBuilding,
			epcBeforeRenovations: right.epcBeforeRenovations,
			epcAfterRenovations: right.epcAfterRenovations,
			buyOutAmount: right.buyOutAmount,
			realtyType: right.realtyType,
			realtyUsageType: right.realtyUsageType,
			purchaseSaleType: right.purchaseSaleType,
			architectFees: right.architectFees,
			landPurchaseType: right.landPurchaseType,
			borrowersInfo: getBorrowersInfo(right),
			renovationCosts: right.renovationCost ? [right.renovationCost] : undefined,
			financialPlanLoanAmountItems,
			financialPlanOwnFundItems,
		};
	}
}

export class SimulatorBorrowerToClientMapper implements MapperStrategy<Partial<Simulator.Borrower>, Partial<Client>> {
	mapToRight(dto: Partial<Simulator.Borrower>): Partial<Client> {
		return {
			id: dto?.id,
			numberOfDependents: dto?.numberOfDependents,
			birthDate: dto?.birthDate?.toString(),
		};
	}

	mapToLeft(entity: Partial<Client>): Partial<Simulator.Borrower> {
		return {
			id: entity?.id,
			birthDate: entity?.birthDate ? new Date(entity.birthDate) : undefined,
			numberOfDependents: entity?.numberOfDependents,
		};
	}
}

function getBorrowersInfo(dto: Partial<BorrowerSimulationDto>): Simulator.Borrower[] {
	const borrowers: Simulator.Borrower[] = [];
	if (dto?.mainBorrowerIncomes?.length > 0) {
		borrowers.push({
			incomes: dto.mainBorrowerIncomes ?? [],
			liabilities: dto.mainBorrowerLiabilities ?? [],
			...dto.mainBorrowerPersonalDetails,
		});
	}

	if (dto?.coBorrowerIncomes?.length > 0) {
		borrowers.push({
			incomes: dto.coBorrowerIncomes ?? [],
			liabilities: dto.coBorrowerLiabilities ?? [],
			...dto.coBorrowerPersonalDetails,
		});
	}

	return borrowers;
}
