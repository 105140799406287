import { LoanPurposeEnums } from '../constants/loan-purpose.constants';
import { Resource, ResourceExtended } from '../models/resource.model';
import { MapperStrategy } from '../utils/mapper.utils';

export class ResourceToLoanPurposeAnswerMapper implements MapperStrategy<Partial<Resource>, Partial<ResourceExtended>> {
	constructor(private readonly translationKeyPrefix?: string) {}

	mapToRight(left: Partial<Resource>): Partial<ResourceExtended> {
		return {
			id: left.id,
			definition: left.definition,
			key: left.key,
			resourceType: left.resourceType,
			icon: left.definition ? ResourceToLoanPurposeAnswerMapper.getIconByPurposeDefinition(left.definition) : undefined,
			translationKey: left.definition ? `${this.translationKeyPrefix ?? 'ç'}.${left.definition}` : undefined,
		};
	}

	mapToLeft(right: Partial<ResourceExtended>): Partial<Resource> {
		return {
			id: right.id,
			definition: right.definition,
			resourceType: right.resourceType,
			key: right.key,
		};
	}

	static getIconByPurposeDefinition(definition: string): string {
		switch (definition) {
			case LoanPurposeEnums.PURCHASE:
				return 'faKey';
			case LoanPurposeEnums.NEW_BUILD:
				return 'faHammer';
			case LoanPurposeEnums.RENOVATION:
				return 'faPaintRoller';
			case LoanPurposeEnums.REFINANCE:
				return 'faSackDollar';
			case LoanPurposeEnums.BUY_OUT:
				return 'faHouseCircleCheck';
			default:
				return 'faHome';
		}
	}
}
