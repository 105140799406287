<div class="container">
	<div class="button-container left">
		<span (click)="decrease()" class="button" [class.disabled]="value === min">
			<fa-icon class="fa" [icon]="iconMinus"></fa-icon>
		</span>
	</div>

	<span class="value-text center"> {{ value }} {{ unit }} </span>

	<div class="button-container right">
		<span (click)="increase()" class="button" [class.disabled]="value === max">
			<fa-icon class="fa" [icon]="iconPlus"></fa-icon>
		</span>
	</div>
</div>
