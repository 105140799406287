import { Component, OnInit } from '@angular/core';
import { MortgageReportOfferCardComponent } from '../mortgage-report-offer-card/mortgage-report-offer-card.component';
import { BehaviorSubject } from 'rxjs';
import { ChartValue } from '../mortgage-report-payment-diagram/mortgage-report-payment-diagram.component';
import { FormControl, FormGroup } from '@angular/forms';

type ChartType = 'm' | 'y';

@Component({
	selector: 'oper-client-mortgage-report-offer-card-ch',
	templateUrl: './mortgage-report-offer-card-ch.component.html',
	styleUrls: ['./mortgage-report-offer-card-ch.component.scss'],
})
export class MortgageReportOfferCardChComponent extends MortgageReportOfferCardComponent implements OnInit {
	private static entityId = 0;

	readonly MONTHLY_SWITCH_VALUE: ChartType = 'm';
	readonly YEARLY_SWITCH_VALUE: ChartType = 'y';
	readonly toggleValue$ = new BehaviorSubject<ChartType>(this.YEARLY_SWITCH_VALUE);
	readonly chartValue$ = new BehaviorSubject<ChartValue>({});

	readonly toggleName: string = `periodicity-${++MortgageReportOfferCardChComponent.entityId}`;
	public form: FormGroup = new FormGroup({
		[this.toggleName]: new FormControl(this.toggleValue$.value),
	});

	ngOnInit() {
		this.chartValue$.next({
			additionalCost: this.mortgageReport.yearlyAdditionalCost,
			amortizationPayment: this.mortgageReport.yearlyAmortizationPayment,
			interestPayment: this.mortgageReport.yearlyInterestPayment,
			payment: this.mortgageReport.yearlyAmount,
		});
	}

	onSwitchChanged(value: ChartType) {
		this.toggleValue$.next(value);
		if (value === this.MONTHLY_SWITCH_VALUE) {
			this.chartValue$.next({
				additionalCost: this.mortgageReport.monthlyAdditionalCost,
				amortizationPayment: this.mortgageReport.monthlyAmortizationPayment,
				interestPayment: this.mortgageReport.monthlyInterestPayment,
				payment: this.mortgageReport.monthlyAmount,
			});
		} else {
			this.chartValue$.next({
				additionalCost: this.mortgageReport.yearlyAdditionalCost,
				amortizationPayment: this.mortgageReport.yearlyAmortizationPayment,
				interestPayment: this.mortgageReport.yearlyInterestPayment,
				payment: this.mortgageReport.yearlyAmount,
			});
		}
	}

	durationChangedCH(value: number) {
		this.durationChangedCard.emit({
			baseProduct: { id: this.mortgageReport.baseProduct.id },
			variability: { id: this.mortgageReport.variability.id },
			durationInput: value * 12,
			key: this.componentKey,
		});
	}
}
