import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[operClientCollapsibleHeader]',
})
export class CollapsibleHeaderDirective {
	@Input() showArrow: boolean;

	constructor(public templateRef: TemplateRef<any>) {}
}
