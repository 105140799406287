import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights, APP_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { SimulatorFlowConfig } from '../interface/simulator-flow-config';
import { clerSimulatorFlowConfig } from './cler-simulator-flow';
import { defaultSimulatorFlowConfig } from './default';
import { huSimulatorFlowConfig } from './hu-simulator-flow.ts';
import { triodosSimulatorFlowConfig } from './triodos-simulator-flow';
import { wirSimulatorFlowConfig } from './wir-simulator-flow';
import { austriaSimulatorConfig } from './austria-simulator-flow';
import { germanSimulatorConfig } from './german-simulator-flow';

@Injectable({
	providedIn: 'root',
})
export class MortgageSimulatorConfigService {
	private readonly _customer: string;
	private readonly _customerSeparator: string = '-';

	private readonly simulationFlowConfig: Record<string, (yearlyPeriodicity?: boolean) => SimulatorFlowConfig[]> = {
		default: defaultSimulatorFlowConfig,
		cler: clerSimulatorFlowConfig,
		triodos: triodosSimulatorFlowConfig,
		swiss: clerSimulatorFlowConfig,
		wir: wirSimulatorFlowConfig,
		finporta: huSimulatorFlowConfig,
		austria: austriaSimulatorConfig,
		hypoooe: austriaSimulatorConfig,
		volksbank: austriaSimulatorConfig,
		volksbankwien: austriaSimulatorConfig,
		volksbanktirol: austriaSimulatorConfig,
		german: germanSimulatorConfig,
	};

	constructor(@Inject(APP_INSIGHTS_CONFIG) private applicationInsights: ApplicationInsights) {
		this._customer = this.getCustomer();
	}

	public getSimulatorFlowConfig(yearlyPeriodicity?: boolean): SimulatorFlowConfig[] {
		return (this.simulationFlowConfig[this._customer] ?? this.simulationFlowConfig.default)(yearlyPeriodicity);
	}

	private getCustomer(): string | null {
		const name = this.applicationInsights?.name;
		return !name.includes(this._customerSeparator) || name.split(this._customerSeparator).length === 0
			? null
			: name.split(this._customerSeparator)[0];
	}
}
