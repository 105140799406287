import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomTranslations } from '@oper-client/shared/data-model';
import { LanguageService } from '@oper-client/shared/util-language';
import { DestroyableComponent } from '@shared/util-component';

@Component({
	selector: 'oper-client-custom-translation',
	templateUrl: './custom-translation.component.html',
	styleUrls: ['./custom-translation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTranslationComponent extends DestroyableComponent implements OnInit, OnChanges {
	@Input() customTranslations: CustomTranslations;
	@Input() field: string;
	@Input() fallback: string;

	readonly translation$ = new BehaviorSubject<string>(null);
	private changeTranslation$ = new Subject<void>();

	constructor(private readonly languageService: LanguageService) {
		super();
	}
	ngOnInit(): void {
		this.changeTranslation$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const currentLanguage = this.languageService.getCurrentLanguage();
			const defaultLanguage = this.languageService.getDefaultLanguage();
			const translationMap = this.customTranslations?.[this.field];

			this.translation$.next(translationMap?.[currentLanguage] || translationMap?.[defaultLanguage] || this.fallback || null);
		});

		this.languageService.languageChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => this.changeTranslation$.next());
	}

	ngOnChanges(): void {
		this.changeTranslation$.next();
	}
}
