import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import * as Icons from '@oper-client/shared/util-fontawesome';
import { NavigationMenuItem } from '../../interfaces/navigation-menu-item';

@Component({
	selector: 'oper-client-top-navigation-menu-item',
	templateUrl: './top-navigation-menu-item.component.html',
	styleUrl: './top-navigation-menu-item.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationMenuItemComponent {
	@Input() icon: `${keyof typeof Icons}` | null = null;
	@Input() label = 'ç.misc.undefined';
	@Input() route: string | any[] | null = null;
	@Input() highlight = false;
	@Output() clicked = new EventEmitter<NavigationMenuItem>();

	getValue(): NavigationMenuItem {
		return {
			icon: this.icon,
			label: this.label,
			route: this.route,
		};
	}
}
