import { Injectable } from '@angular/core';
import { SessionStorageService } from '@oper-client/shared/util-client-storage';
import { LeadingParams } from '../constants/url-param.constant';
import { LanguageService } from '@oper-client/shared/util-language';
import { LanguageParam } from '../constants/url-param.constant';
import { ResourceType } from '@oper-client/shared/data-model';
import { ResourcesFacade } from '@oper-client/shared/resources/data-access-resource';
import { delay, filter, map, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UrlParamService {
	constructor(
		private readonly sessionStorageService: SessionStorageService,
		private readonly languageService: LanguageService,
		private readonly resourceFacade: ResourcesFacade
	) {}

	getOfficeLeadingParam(): number | null {
		const office = this.sessionStorageService.get(LeadingParams.OFFICE) || null;
		return office ? +office : null;
	}

	getSourceLeadingParam(): string | null {
		const source = this.sessionStorageService.get(LeadingParams.SOURCE) || null;
		return source;
	}

	handleLeadingParams(params: Record<string, any>): void {
		this.storeParamToSessionStorage(params, LeadingParams.OFFICE);
		this.storeParamToSessionStorage(params, LeadingParams.SOURCE);
	}

	disableOfficeSelection(): void {
		this.sessionStorageService.set(LeadingParams.DISABLE_OFFICE_SELECTION, 'true');
	}

	unsetOfficeSelection(): void {
		this.sessionStorageService.unset(LeadingParams.DISABLE_OFFICE_SELECTION);
	}

	isOfficeSelectionDisabled(): boolean {
		return this.sessionStorageService.get(LeadingParams.DISABLE_OFFICE_SELECTION) === 'true';
	}

	handleLanguageParm(params: Record<string, any>): void {
		if (params[LanguageParam.LANGUAGE]) {
			this.resourceFacade.resources$
				.pipe(
					map((resources) => resources[ResourceType.LANGUAGE]),
					filter((x) => x && x.length > 0),
					take(1),
					delay(100)
				)
				.subscribe((languages) => this.languageService.changeLanguage(params[LanguageParam.LANGUAGE], languages));
		}
	}

	unsetLeadingParams(): void {
		this.sessionStorageService.unset(LeadingParams.SOURCE);
		this.sessionStorageService.unset(LeadingParams.OFFICE);
	}

	private storeParamToSessionStorage(params: Record<string, any>, key: string): void {
		if (params[key]) {
			this.sessionStorageService.set(key, params[key]);
		}
	}
}
