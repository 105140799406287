import { LoanApplicationFeatureConfiguration } from '../../interface/loan-application-feature.interface';
import defaultFeatureConfiguration from './default-loan-application.configuration';

const featureConfiguration: LoanApplicationFeatureConfiguration = {
	...defaultFeatureConfiguration,
	steps: [
		{
			name: 'aboutProject',
			title: 'ç.feature.selfService.steps.aboutProject.title',
			steps: [
				{
					name: 'aboutProject.projectDetails',
					title: 'ç.feature.selfService.steps.projectDetails.title',
					description: 'ç.feature.selfService.steps.projectDetails.description',
					formName: 'loanApplicationProjectDetails',
					next: 'aboutProject.yourContribution',
					nextLabel: 'ç.misc.action.next',
				},
				{
					name: 'aboutProject.yourContribution',
					title: 'ç.feature.selfService.steps.yourContribution.title',
					description: 'ç.feature.selfService.steps.yourContribution.description',
					formName: 'loanApplicationYourContribution',
					next: 'financialDetails.income',
					nextLabel: 'ç.misc.action.next',
					back: 'aboutProject.projectDetails',
					backLabel: 'ç.misc.action.back',
				},
			],
		},
		{
			name: 'financialDetails',
			title: 'ç.feature.selfService.steps.financialDetails.title',
			steps: [
				{
					name: 'financialDetails.income',
					title: 'ç.feature.selfService.steps.income.title',
					description: 'ç.feature.selfService.steps.income.description',
					formName: 'loanApplicationIncome',
					back: 'aboutProject.yourContribution',
					backLabel: 'ç.misc.action.back',
					next: 'financialDetails.liability',
					nextLabel: 'ç.misc.action.next',
				},
				{
					name: 'financialDetails.liability',
					title: 'ç.feature.selfService.steps.liability.title',
					description: 'ç.feature.selfService.steps.liability.description',
					formName: 'loanApplicationLiability',
					back: 'financialDetails.income',
					backLabel: 'ç.misc.action.back',
					next: 'personalDetails',
					nextLabel: 'ç.misc.action.next',
				},
			],
		},
		{
			name: 'personalDetails',
			title: 'ç.feature.selfService.steps.personalDetails.title',
			description: 'ç.feature.selfService.steps.personalDetails.description',
			formName: 'loanApplicationPersonalDetails',
			back: 'financialDetails.liability',
			backLabel: 'ç.misc.action.back',
			next: 'loanDetails',
			nextLabel: 'ç.misc.action.next',
		},
		{
			name: 'loanDetails',
			title: 'ç.feature.selfService.steps.loanDetails.title',
			description: 'ç.feature.selfService.steps.loanDetails.description',
			back: 'personalDetails',
			backLabel: 'ç.misc.action.back',
			next: 'uploadDocuments',
			nextLabel: 'ç.misc.action.next',
			disabled: true,
			tooltip: 'ç.feature.mortgageSimulator.commingSoon',
		},
		{
			name: 'uploadDocuments',
			title: 'ç.feature.selfService.steps.uploadDocuments.title',
			description: 'ç.feature.selfService.steps.uploadDocuments.description',
			back: 'loanDetails',
			backLabel: 'ç.misc.action.back',
			next: 'propertyValuation',
			nextLabel: 'ç.misc.action.next',
			disabled: true,
			tooltip: 'ç.feature.mortgageSimulator.commingSoon',
		},
		{
			name: 'propertyValuation',
			title: 'ç.feature.selfService.steps.propertyValuation.title',
			description: 'ç.feature.selfService.steps.propertyValuation.description',
			back: 'uploadDocuments',
			backLabel: 'ç.misc.action.back',
			disabled: true,
			tooltip: 'ç.feature.mortgageSimulator.commingSoon',
		},
	],
};

export default featureConfiguration;
