<div class="offer-card">
	<div class="content">
		<div class="header">
			<span class="title">
				<oper-client-custom-translation
						[field]="'name'"
						[customTranslations]="mortgageReport.baseProduct?.customTranslations"
						[fallback]="mortgageReport.baseProduct?.name"
				>
				</oper-client-custom-translation
				></span>
			<h4>
				{{ mortgageReport?.variability?.definition | translateResource: 'variability' | uppercase }}
				<span [ngSwitch]="mortgageReport?.variability?.definition">
					<oper-client-tooltip-indicator
							*ngSwitchCase="'fix'"
							[text]="'ç.feature.selfService.steps.mortgageReport.payment.variabilityRates.fix' | translate"
					></oper-client-tooltip-indicator>
					<oper-client-tooltip-indicator
							*ngSwitchDefault
							[text]="'ç.feature.selfService.steps.mortgageReport.payment.variabilityRates.variable' | translate"
					></oper-client-tooltip-indicator>
				</span>
			</h4>
		</div>

		<div class="block">
			<oper-client-duration-selector-buttons
					*ngIf="showDurationInput$ | async; else calendar"
					[value]="mortgageReport.duration / 12"
					[min]="mortgageReport.configurationValues.minDuration / 12"
					[max]="mortgageReport.configurationValues.maxDuration / 12"
					(changed)="durationChangedCH($event)"
			></oper-client-duration-selector-buttons>
			<ng-template #calendar>
				<div class="container">
					<div class="duration-container w100">
						<span class="icon">
							<fa-icon [icon]="iconCalendar"></fa-icon>
						</span>
						<span class="value bold">{{ mortgageReport.duration / 12 }} {{ 'ç.misc.duration.year.plural' | translate }}</span>
					</div>
				</div>
			</ng-template>
		</div>

		<div class="block">
			<div [class.loading]="(mortgageReportProcessing$|async) && (lastClickComponentKey$ | async) === componentKey"></div>
			<div class="container">
				<div class="diagram-container">
					<form [formGroup]="form">
						<oper-client-toggle-switch
								[formControlName]="toggleName"
								[labelA]="'ç.feature.selfService.steps.mortgageReport.payment.perMonth'"
								[labelB]="'ç.feature.selfService.steps.mortgageReport.payment.perYear'"
								[valueA]="MONTHLY_SWITCH_VALUE"
								[valueB]="YEARLY_SWITCH_VALUE"
								[value]="toggleValue$ | async"
								(valueChanged)="onSwitchChanged($event)"
								[backgroundColor]="'grey'"
								[buttonColor]="'primary'"
								class="switch"
						></oper-client-toggle-switch>
					</form>
					<div class="value-container w100">
						<span class="dot dot--primary"></span>
						<span class="text">{{ 'ç.feature.selfService.steps.mortgageReport.payment.amortisation.label' | translate }}</span>
						<span class="amount" data-test="label--amortizationAmount">
							<ng-container *ngIf="(chartValue$ | async).amortizationPayment !== null">
								{{ (chartValue$ | async).amortizationPayment | environmentCurrency: '1.0-0' }}
							</ng-container>
						</span>
					</div>

					<div class="value-container w100">
						<span class="dot dot--secondary"></span>
						<span class="text">{{ 'ç.feature.selfService.steps.mortgageReport.payment.interest.label' | translate }}</span>
						<span class="amount" data-test="label--interestAmount">
							<ng-container *ngIf="(chartValue$ | async).interestPayment !== null">
								{{ (chartValue$ | async).interestPayment | environmentCurrency: '1.0-0' }}
							</ng-container>
						</span>
					</div>

					<div class="value-container w100">
						<span class="dot dot--dot--tertiary"></span>
						<span class="text">{{ 'ç.feature.selfService.steps.mortgageReport.payment.otherCosts.label' | translate }}</span>
						<span class="amount" data-test="label--addCostsAmount">
							<ng-container *ngIf="(chartValue$ | async).additionalCost !== null">
								{{ (chartValue$ | async).additionalCost | environmentCurrency: '1.0-0' }}
							</ng-container>
						</span>
					</div>
				</div>
			</div>

			<div class="container last">
				<div class="value-container w50">
					<div class="label">{{ 'ç.feature.selfService.steps.mortgageReport.payment.interestRate' | translate }}</div>
					<div>
						<span class="amount" data-test="label--rateOfferValue">{{
                            mortgageReport.configurationValues.secondaryInterestRate | percent
							}}</span>
					</div>
				</div>
				<div class="value-container w50">
					<div class="label end">{{ 'ç.feature.selfService.steps.mortgageReport.payment.monthlyPayment.label' | translate }}</div>
					<div class="amount end" data-test="label--monthlyAmountOfferValue">
						{{ mortgageReport.monthlyAmount | environmentCurrency: '1.0-0' }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
