<div class="offer-card" #container>
	<div class="content">
		<div class="header">
			<div class="title">
				<oper-client-custom-translation
					[field]="'name'"
					[customTranslations]="mortgageReport.baseProduct?.customTranslations"
					[fallback]="mortgageReport.baseProduct?.name"
				></oper-client-custom-translation>
			</div>
			<div class="subtitle">
				{{ mortgageReport.baseProduct.loanType.definition | translateResource : 'loan-type' | uppercase }}
				{{ mortgageReport?.variability?.definition | translateResource : 'variability' | uppercase }}
				<oper-client-tooltip-indicator [text]="offerTooltipText$ | async | translate"></oper-client-tooltip-indicator>
			</div>
		</div>

		<div class="block">
			<oper-client-duration-selector-buttons
				*ngIf="showDurationInput$ | async; else calendar"
				[value]="mortgageReport.duration / 12"
				[min]="mortgageReport.configurationValues.minDuration / 12"
				[max]="mortgageReport.configurationValues.maxDuration / 12"
				[unit]="'ç.misc.duration.year.plural' | translate"
				(changed)="durationChangedBE($event)"
			></oper-client-duration-selector-buttons>
			<ng-template #calendar>
				<div class="container">
					<div class="duration-container w100">
						<span class="icon">
							<fa-icon [icon]="iconCalendar"></fa-icon>
						</span>
						<span class="value bold">{{ mortgageReport.duration / 12 }} {{ 'ç.misc.duration.year.plural' | translate }}</span>
					</div>
				</div>
			</ng-template>
		</div>

		<div class="block">
			<div [class.loading]="(mortgageReportProcessing$ | async) && (lastClickComponentKey$ | async) === componentKey"></div>
			<div class="container">
				<div class="value-container w50 left" *ngIf="mortgageReport.configurationValues?.interestRate !== null">
					<div class="label">{{ 'ç.feature.selfService.steps.mortgageReport.payment.interestRate' | translate }}</div>
					<div class="value" data-test="label--rateOfferValue">
						{{ mortgageReport.configurationValues.interestRate | percent }}
					</div>
				</div>
				<div class="value-container w50 right" *ngIf="mortgageReport.configurationValues?.aprc !== null">
					<div class="label">
						{{ 'ç.feature.selfService.steps.mortgageReport.payment.aprc' | translate }}
						<oper-client-mortgage-report-payment-aprc-tooltip class="aprc" [mortgageReport]="mortgageReport">
						</oper-client-mortgage-report-payment-aprc-tooltip>
					</div>
					<div class="value" data-test="label--arpcOfferValue">{{ mortgageReport.configurationValues.aprc | percent }}</div>
				</div>
			</div>

			<div class="container">
				<div class="value-container w100">
					<div class="label">{{ 'ç.feature.selfService.steps.mortgageReport.payment.monthlyPayment.label' | translate }}</div>
					<div>
						<span class="amount" data-test="label--monthlyAmountOfferValue">
							{{ mortgageReport.monthlyAmount | environmentCurrency : '1.0-0' }}</span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
