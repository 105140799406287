import { IApiService } from '@oper-client/shared/data-access';
import { throwError, combineLatest } from 'rxjs';
import { LanguageService } from '@oper-client/shared/util-language';
import { catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { FeatureFlagService } from '@oper-client/shared/util-feature-flag';
import { FeatureFlag } from '@oper-client/shared/data-model';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';

export class AppointmentBookingService {
	private readonly appointmentBookingEnabled$ = this.featureFlagService.hasFeatureFlag(FeatureFlag.SELF_SERVICE_APPOINTMENT_BOOKING);
	private readonly appointmentBookingNavigationEnabled$ = this.featureFlagService.hasFeatureFlag(
		FeatureFlag.SELF_SERVICE_APPOINTMENT_BOOKING_NAVIGATION
	);

	constructor(
		private readonly apiService: IApiService,
		private readonly languageService: LanguageService,
		private readonly featureFlagService: FeatureFlagService,
		private readonly localStorageService: LocalStorageService
	) {}

	public setBookingUrl(): void {
		combineLatest([this.languageService.languageChanged$, this.appointmentBookingEnabled$, this.appointmentBookingNavigationEnabled$])
			.pipe(
				filter(([language]) => !!language),
				filter(([, bookingEnabled, bookingEnabledNavigation]) => !!bookingEnabled || !!bookingEnabledNavigation),
				distinctUntilChanged((prev, curr) => arrayEquals(prev, curr))
			)
			.subscribe(([language]) => {
				this.setUrlByLanguage(language);
			});
	}

	private setUrlByLanguage(language: string) {
		this.apiService
			.get(`/api/appointment-booking-websites/${language}`)
			.pipe(catchError((error) => throwError(error)))
			.subscribe((response) => {
				if (response.value) {
					this.localStorageService.set('appointmentBookingUrl', response.value);
				}
			});
	}
}

function arrayEquals(a: Array<any>, b: Array<any>): boolean {
	return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}
