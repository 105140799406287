import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { faAngleDown, faAngleUp } from '@oper-client/shared/util-fontawesome';
import { CollapsibleContentDirective } from './directives/collapsible-content.directive';
import { CollapsibleHeaderDirective } from './directives/collapsible-header.directive';
import { openCloseAnimation } from '../../animations/open-close.animation';

@Component({
	selector: 'oper-client-collapsible',
	templateUrl: './collapsible.component.html',
	styleUrls: ['./collapsible.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [openCloseAnimation(200)],
})
export class CollapsibleComponent {
	readonly angleDownIcon = faAngleDown;
	readonly angleUpIcon = faAngleUp;

	@Input() open = true;
	@Input() disabled = false;
	@Input() offsetBackground = false;
	@Input() toggleCollapseOnlyOnArrowClick = false;

	@Output() headerClicked = new EventEmitter<void>();
	@Output() contentClicked = new EventEmitter<void>();

	@ContentChild(CollapsibleContentDirective) content: CollapsibleContentDirective;
	@ContentChild(CollapsibleHeaderDirective) header: CollapsibleHeaderDirective;

	handleCollapse(): void {
		this.headerClicked.emit();
		if (!this.disabled && !this.toggleCollapseOnlyOnArrowClick) {
			this.open = !this.open;
		}
	}

	arrowClick(): void {
		if (this.toggleCollapseOnlyOnArrowClick) {
			this.open = !this.open;
		}
	}
}
