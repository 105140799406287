import { Injectable } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';

import { LinkPartialState } from './link.reducer';
import * as LinkSelectors from './link.selectors';
import * as linkActions from './link.actions';

@Injectable()
export class LinkFacade {
	links$ = this.store.pipe(select(LinkSelectors.getLinks));

	constructor(private store: Store<LinkPartialState>) {}

	public loadLinks(): void {
		this.dispatch(linkActions.loadLinks());
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}
}
