import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UiModule } from '../../ui.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'oper-client-simulation-report-discounts-applied',
	standalone: true,
	imports: [UiModule, TranslateModule],
	templateUrl: './simulation-report-discounts-applied.component.html',
	styleUrl: './simulation-report-discounts-applied.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportDiscountsAppliedComponent {}
