<h3 class="title">Discounts applied</h3>
<div class="checking-account">
	<div class="checking-account__toggle">
		<oper-client-toggle-switch
			class="checking-account__switch"
			backgroundColor="grey"
			[valueA]="false"
			[valueB]="true"
			[disabled]="true"
		></oper-client-toggle-switch>
		<p class="checking-account__label">
			Checking account
			<oper-client-tooltip-indicator
				[text]="'Open a checking account at MeDirect and get a discount.'"
			></oper-client-tooltip-indicator>
		</p>
	</div>
	<div class="checking-account__percentage">0.0%</div>
</div>
