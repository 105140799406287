<oper-client-tooltip-indicator
	*ngIf="aprcConfigurationParam$ | async as aprcConf"
	[text]="
		'ç.feature.selfService.steps.mortgageReport.payment.aprcDescription'
			| translate
				: {
						fileCost: aprcConf.fileCost | environmentCurrency: digitsInfo,
						estimationCost: aprcConf.estimationCost | environmentCurrency: digitsInfo,
						costRegistrationNotary: aprcConf.costRegistrationNotary | environmentCurrency: digitsInfo,
						liabilityInsurance: aprcConf.liabilityInsurance | environmentCurrency: digitsInfo,
						liabilityInsuranceDuration: aprcConf.liabilityInsuranceDuration | productDuration: true | lowercase,
						fireInsurance: aprcConf.fireInsurance | environmentCurrency: digitsInfo,
						fireInsuranceDuration: aprcConf.fireInsuranceDuration | productDuration: true | lowercase
				  }
	"
	[parentContainer]="tooltipContainer"
></oper-client-tooltip-indicator>
