import {
	AfterContentChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	Output,
	ViewChild,
	computed,
	input,
	signal,
} from '@angular/core';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights, RedirectUrlElementEnum } from '@oper-client/shared/configuration';
import { Resource } from '@oper-client/shared/data-model';
import { NavigationMenuItem, TopNavigationMenuComponent, TopNavigationMenuItemDirective } from '@oper-client/ui';
import { DestroyableComponent } from '@shared/util-component';
import { takeUntil } from 'rxjs';

const DefaultRoutes = {
	START: '/start',
	DASHBOARD: '/dashboard',
};

@Component({
	selector: 'oper-client-self-service-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends DestroyableComponent implements AfterViewInit, AfterContentChecked {
	readonly redirectUrlElement = RedirectUrlElementEnum.HEADER_LOGO;
	readonly hasMultipleMenuItems = signal<boolean>(false);

	@Input() languages: Resource[] | null = [];
	@Input() currentLanguage: string | null = null;
	@Input() isDocumentsFeatureShown = false;
	@Input() isContactsFeatureShown = false;
	@Input() isLanguageSelectorShown = false;
	@Input() isLanguageSelectorExpanded = false;
	@Input() isHamburgerMenuShown = false;

	@Output() hamburgerMenuClick = new EventEmitter<void>();
	@Output() menuItemClick = new EventEmitter<NavigationMenuItem>();
	@Output() logoutClick = new EventEmitter<void>();
	@Output() openLanguageSelector = new EventEmitter<void>();
	@Output() closeLanguageSelector = new EventEmitter<void>();
	@Output() languageChange = new EventEmitter<string>();

	@ViewChild('navigationMenu', { static: false }) navigationMenu: TopNavigationMenuComponent | undefined;

	readonly isAuthenticated = input<boolean>(false);
	readonly isUserVerified = input<boolean>(false);
	readonly isSimulatorFeatureShown = input<boolean>(false);
	readonly isSimulatorNewVersionEnabled = input<boolean>(false);
	readonly logoRouterLink = computed(() => this.getLogoRouterLink());

	constructor(@Inject(CUSTOMER_INSIGHTS_CONFIG) private readonly customerInsights: CustomerInsights) {
		super();
	}

	ngAfterContentChecked(): void {
		this.hasMultipleMenuItems.set(this.getNumberOfVisibleMenuItems() > 1);
	}

	ngAfterViewInit(): void {
		this.navigationMenu.menuItems.changes
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.hasMultipleMenuItems.set(this.getNumberOfVisibleMenuItems() > 1));
	}

	onHamburgerMenuItemClick(item: TopNavigationMenuItemDirective): void {
		item.clicked.emit(item.getValue());
		this.menuItemClick.emit(item.getValue());
	}

	onOutsideHamburgerMenuClick(): void {
		this.hamburgerMenuClick.emit();
	}

	private getLogoRouterLink(): string | null {
		if (this.isAuthenticated() && this.isUserVerified()) {
			return DefaultRoutes.DASHBOARD;
		} else {
			const configuredRedirectUrl = this.getConfiguredRedirectUrl();
			if (configuredRedirectUrl) {
				return configuredRedirectUrl;
			} else if (this.isSimulatorFeatureShown()) {
				return DefaultRoutes.START;
			} else {
				return null;
			}
		}
	}

	private getNumberOfVisibleMenuItems(): number {
		if (!this.navigationMenu) {
			return 0;
		}

		return this.navigationMenu.menuItems.filter((item) => item.isShown).length;
	}

	private getConfiguredRedirectUrl(): string | null {
		return this.isRedirectUrlElementConfigured(this.redirectUrlElement)
			? this.customerInsights.redirectUrl[this.redirectUrlElement]
			: null;
	}

	private isRedirectUrlElementConfigured(element: RedirectUrlElementEnum): boolean {
		return this.customerInsights?.redirectUrl && Object.keys(this.customerInsights.redirectUrl).includes(element);
	}
}
