import { Observable, of } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ResourcesFacade, loadResources } from '@oper-client/shared/resources/data-access-resource';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';

@Injectable({
	providedIn: 'root',
})
export class ResourceResolverService implements Resolve<PartialNormalizedResource> {
	constructor(private readonly resourceFacade: ResourcesFacade) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		const { kinds } = route.data;
		this.resourceFacade.dispatch(loadResources(kinds));

		return of(route.data).pipe(defaultIfEmpty({}));
	}
}
