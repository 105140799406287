import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './components/map/map.component';
import { UiModule } from '@oper-client/ui';
import { AddressAutocompleteComponent } from './components/address-autocomplete/address-autocomplete.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [CommonModule, UiModule, TranslateModule, FormsModule],
	declarations: [MapComponent, AddressAutocompleteComponent],
	exports: [MapComponent, AddressAutocompleteComponent],
})
export class UtilGoogleMapsModule {}
