import { createSelector, createFeatureSelector } from '@ngrx/store';
import { BorrowerModes } from '@oper-client/shared/data-model';

import { APPLICATION_FLOW_KEY, ApplicationFlowState } from './application-flow.reducer';

export const getApplicationFlowFeatureState = createFeatureSelector<ApplicationFlowState>(APPLICATION_FLOW_KEY);

export const getConfiguration = createSelector(getApplicationFlowFeatureState, (state) => state.configuration);
export const getActiveStep = createSelector(getApplicationFlowFeatureState, (state) => state.activeStep);
export const getLoadingIndicator = createSelector(getApplicationFlowFeatureState, (state) => state.loadingIndicator);
export const getBorrowerMode = createSelector(getApplicationFlowFeatureState, (state) => state.borrowerMode);
export const getError = createSelector(getApplicationFlowFeatureState, (state) => state.error);

export const getEnableSecondBorrower = createSelector(
	getApplicationFlowFeatureState,
	(state) => [BorrowerModes.MULTI, BorrowerModes.MARRIED].indexOf(state.borrowerMode) > -1
);
