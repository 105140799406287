import { LoanApplicationDto } from '../dto/loan-application.dto';
import { Client } from '../models/client.model';
import { Income } from '../models/income.model';
import { Liability } from '../models/liability.model';
import { LoanRequest } from '../models/loan-request.model';
import { Offer } from '../models/offer.model';
import { Realty } from '../models/realty.model';
import { Simulator } from '../models/simulator.models';
import { MapperStrategy, mapToRight, mapToLeft } from '../utils/mapper.utils';
import { SimulationToBorrowerSimulationDtoMapper } from './simulator.mappers';

export class LoanApplicationDtoToSimulationMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Simulator.Simulation>> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Simulator.Simulation> {
		const simulation = mapToLeft(dto, new SimulationToBorrowerSimulationDtoMapper());
		return {
			...simulation,
		};
	}

	mapToLeft(entity: Partial<Simulator.Simulation>): Partial<LoanApplicationDto> {
		const dto = mapToRight(entity, new SimulationToBorrowerSimulationDtoMapper());
		return {
			...dto,
		};
	}
}

export class LoanApplicationDtoToLoanRequestMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<LoanRequest>> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<LoanRequest> {
		return {
			notary: dto.notary,
			ownFunds: dto.ownFunds,
			purposes: dto.loanPurpose ? [dto.loanPurpose] : undefined,
		};
	}

	mapToLeft(entity: Partial<LoanRequest>): Partial<LoanApplicationDto> {
		return {
			loanRequest: entity,
			loanPurpose: entity.purposes?.[0],
			ownFunds: entity.ownFunds,
			notary: entity.notary,
		};
	}
}

export class LoanApplicationDtoToOfferMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Offer>> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Offer> {
		return dto.offers?.[0];
	}

	mapToLeft(entity: Offer): Partial<LoanApplicationDto> {
		return { offers: [entity] };
	}
}

export class LoanApplicationDtoToClientsMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Client>[]> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Client>[] {
		const result: Partial<Client>[] = [];
		if (dto.mainBorrowerPersonalDetails) {
			result.push({
				...dto.clients?.[0],
				id: dto.clients?.[0]?.id,
				birthDate: dto.mainBorrowerPersonalDetails?.birthDate?.toString(),
				firstName: dto.mainBorrowerPersonalDetails.firstName,
				lastName: dto.mainBorrowerPersonalDetails.lastName,
				numberOfDependents: dto.mainBorrowerPersonalDetails.numberOfDependents,
			});
		}

		if (dto.coBorrowerPersonalDetails) {
			result.push({
				...dto.clients?.[1],
				id: dto.clients?.[1]?.id,
				birthDate: dto.coBorrowerPersonalDetails?.birthDate?.toString(),
				firstName: dto.coBorrowerPersonalDetails.firstName,
				lastName: dto.coBorrowerPersonalDetails.lastName,
				numberOfDependents: dto.coBorrowerPersonalDetails.numberOfDependents,
			});
		}

		return result;
	}

	mapToLeft(entities: Partial<Client>[]): Partial<LoanApplicationDto> {
		const mainBorrower = entities[0];
		const coBorrower = entities[1];
		return {
			clients: entities as Client[],
			mainBorrowerPersonalDetails: mainBorrower
				? {
						id: mainBorrower.id,
						birthDate: mainBorrower?.birthDate ? new Date(entities[0].birthDate) : undefined,
						firstName: mainBorrower?.firstName,
						lastName: mainBorrower?.lastName,
						numberOfDependents: mainBorrower.numberOfDependents,
					}
				: undefined,
			coBorrowerPersonalDetails: coBorrower
				? {
						id: coBorrower.id,
						birthDate: coBorrower.birthDate ? new Date(coBorrower.birthDate) : undefined,
						firstName: coBorrower.firstName,
						lastName: coBorrower.lastName,
						numberOfDependents: coBorrower.numberOfDependents,
					}
				: undefined,
		};
	}
}

export class LoanApplicationDtoToLiabilitiesMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Liability>[]> {
	constructor(private readonly leftState?: Partial<LoanApplicationDto>) {}

	mapToRight(left: Partial<LoanApplicationDto>): Partial<Liability>[] {
		const result: Partial<Liability>[] = [];

		if (left.mainBorrowerLiabilities) {
			result.push(...left.mainBorrowerLiabilities);
		}

		if (left.coBorrowerLiabilities) {
			result.push(...left.coBorrowerLiabilities);
		}

		return result;
	}

	mapToLeft(right: Partial<Liability>[]): Partial<LoanApplicationDto> {
		const mainBorrowerId = this.leftState?.mainBorrowerPersonalDetails?.id;
		const coBorrowerId = this.leftState?.coBorrowerPersonalDetails?.id;
		return {
			liabilities: right as Liability[],
			mainBorrowerLiabilities: right?.filter((liability) => liability?.client?.id === mainBorrowerId) as Simulator.Liability[],
			coBorrowerLiabilities: right?.filter((liability) => liability?.client?.id === coBorrowerId) as Simulator.Liability[],
		};
	}
}

export class LoanApplicationDtoToIncomesMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Income>[]> {
	constructor(private readonly leftState?: Partial<LoanApplicationDto>) {}

	mapToRight(left: Partial<LoanApplicationDto>): Partial<Income>[] {
		const result: Partial<Income>[] = [];

		if (left.mainBorrowerIncomes) {
			result.push(...left.mainBorrowerIncomes);
		}

		if (left.coBorrowerIncomes) {
			result.push(...left.coBorrowerIncomes);
		}

		return result;
	}

	mapToLeft(right: Partial<Income>[]): Partial<LoanApplicationDto> {
		const mainBorrowerId = this.leftState?.mainBorrowerPersonalDetails?.id;
		const coBorrowerId = this.leftState?.coBorrowerPersonalDetails?.id;

		return {
			incomes: right as Income[],
			mainBorrowerIncomes: right?.filter((income) => income?.client?.id === mainBorrowerId) as Simulator.Income[],
			coBorrowerIncomes: right?.filter((income) => income?.client?.id === coBorrowerId) as Simulator.Income[],
		};
	}
}

export class LoanApplicationDtoToRealtyMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Realty>> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Realty> {
		const renovationCosts = dto.renovationCost ? [dto.renovationCost] : [];
		return {
			...dto.realties?.[0],
			address: dto.address,
			price: dto.realtyPrice?.toString(),
			priceLand: dto.priceLand?.toString(),
			priceBuilding: dto.priceBuilding,
			renovations: renovationCosts.map((cost) => ({ amountContractor: cost.amount, renovationType: cost.type, id: cost.id })),
			epcAfterRenovations: dto.epcAfterRenovations,
			epcBeforeRenovations: dto.epcBeforeRenovations,
			epcDate: dto.epcDate,
			usageTypes: dto.realtyUsageType ? [dto.realtyUsageType] : undefined,
			realtyType: dto.realtyType,
			purchaseSaleType: dto.purchaseSaleType,
			landPurchaseType: dto.landPurchaseType,
			architectFees: dto.architectFees,
			epcContractNumber: dto.epcContractNumber,
		};
	}

	mapToLeft(entity: Realty): Partial<LoanApplicationDto> {
		const renovationCost = entity?.renovations?.[0];
		return {
			realties: [entity],
			realtyUsageType: entity?.usageTypes?.[0],
			realtyType: entity?.realtyType,
			address: entity?.address,
			priceBuilding: +entity?.priceBuilding,
			priceLand: +entity?.priceLand,
			realtyPrice: +entity?.price,
			renovationCost: renovationCost
				? { amount: renovationCost?.amountContractor, type: renovationCost?.renovationType, id: renovationCost?.id }
				: undefined,
			epcAfterRenovations: entity?.epcAfterRenovations,
			epcBeforeRenovations: entity?.epcBeforeRenovations,
			epcDate: entity?.epcDate,
			region: entity?.address?.region,
			purchaseSaleType: entity?.purchaseSaleType,
			landPurchaseType: entity?.landPurchaseType,
			architectFees: entity?.architectFees,
			epcContractNumber: entity?.epcContractNumber,
		};
	}
}
