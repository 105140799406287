import { Realty } from '@oper-client/shared/data-model';
import { FlowStepConfig } from '../interfaces/flow-step-config';

export const triodosRealtyDetailedStepConfig = (data: Partial<Realty>): FlowStepConfig => {
	if (data?.coLivingGroupType?.id) {
		// User answered "Yes" to the co-living question in the Housing type step
		if (data?.loanPurpose?.definition === 'renovation') {
			return {
				title: 'ç.feature.selfService.steps.aboutTheRenovation.title',
				description: 'ç.feature.selfService.steps.aboutTheRenovation.description',
				formConfigurationName: 'coLivingRealtyToRenovate',
			};
		}

		if (data?.loanPurpose?.definition === 'purchase') {
			return {
				title: 'ç.feature.selfService.steps.aboutTheProperty.title',
				description: 'ç.feature.selfService.steps.aboutTheProperty.description',
				formConfigurationName: 'coLivingRenovatedRealty',
			};
		}

		if (data?.loanPurpose?.definition === 'newBuild') {
			return {
				title: 'ç.feature.selfService.steps.aboutTheBuildProject.title',
				description: 'ç.feature.selfService.steps.aboutTheBuildProject.description',
				formConfigurationName: 'realtyBuildProject',
			};
		}
	} else {
		// User answered "No" to the co-living question in the Housing type step
		if (data?.loanPurpose?.definition === 'renovation') {
			return {
				title: 'ç.feature.selfService.steps.aboutTheRenovation.title',
				description: 'ç.feature.selfService.steps.aboutTheRenovation.description',
				formConfigurationName: 'realtyToRenovate',
			};
		}

		if (data?.loanPurpose?.definition === 'purchase') {
			return {
				title: 'ç.feature.selfService.steps.aboutTheProperty.title',
				description: 'ç.feature.selfService.steps.aboutTheProperty.description',
				formConfigurationName: 'renovatedRealty',
			};
		}

		if (data?.loanPurpose?.definition === 'newBuild') {
			return {
				title: 'ç.feature.selfService.steps.aboutTheBuildProject.title',
				description: 'ç.feature.selfService.steps.aboutTheBuildProject.description',
				formConfigurationName: 'realtyBuildProject',
			};
		}
	}
	return {
		title: 'ç.feature.selfService.steps.aboutTheRenovation.title',
		description: 'ç.feature.selfService.steps.aboutTheRenovation.description',
		formConfigurationName: 'renovatedRealty',
	};
};
