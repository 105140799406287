<a
	class="logo-container"
	[routerLink]="logoRouterLink"
	[ngClass]="{ clickable: isLogoClickable }"
	(click)="isLogoClickable && logoClick.emit()"
>
	<img [src]="logoSrc" class="logo" alt="Oper" />
</a>

<oper-client-fontawesome-icon class="hamburger-menu" [icon]="'faBars'" [centered]="true" (click)="hamburgerMenuIconClick.emit()" />

<div class="items">
	@for (item of menuItems; track $index) {
		@if (item.isShown) {
			@if (item.showCustomTemplate) {
				<ng-container [ngTemplateOutlet]="item.templateRef" />
			} @else {
				<oper-client-top-navigation-menu-item
					[icon]="item.icon"
					[label]="item.label"
					[route]="item.route"
					[style.order]="item.order"
					[highlight]="item.highlight"
					(clicked)="onMenuItemClick(item)"
				/>
			}
		}
	}
</div>
