<ng-container [ngSwitch]="region">
	<oper-client-mortgage-report-offer-card-ch
		*ngSwitchCase="ExistingRegionList.CH"
		[mortgageReport]="mortgageReport"
		(durationChangedCard)="durationChanged($event)"
		[simulationFlowType]="simulationFlowType"
		[durationInputFeature]="durationInputFeature"
		[mortgageReportProcessing]="mortgageReportProcessing$ | async"
		[componentKey]="componentKey"
		[parentContainer]="parentContainer"
	></oper-client-mortgage-report-offer-card-ch>

	<oper-client-mortgage-report-offer-card-be
		*ngSwitchDefault
		[mortgageReport]="mortgageReport"
		(durationChangedCard)="durationChanged($event)"
		[simulationFlowType]="simulationFlowType"
		[durationInputFeature]="durationInputFeature"
		[mortgageReportProcessing]="mortgageReportProcessing$ | async"
		[componentKey]="componentKey"
		[parentContainer]="parentContainer"
	></oper-client-mortgage-report-offer-card-be>
</ng-container>
