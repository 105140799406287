import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLink from './link.reducer';

import { LINK_ENTITY_KEY } from './link.reducer';

export const getLinksState = createFeatureSelector<fromLink.LinkState>(LINK_ENTITY_KEY);

const selectors = fromLink.linkAdapter.getSelectors();

export const getLinks = createSelector(getLinksState, selectors.selectAll);
