import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import * as fromLinkFeature from './+state/link.reducer';
import { LinkEffects } from './+state/link.effects';
import { LinkFacade } from './+state/link.facade';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([LinkEffects]),
		StoreModule.forFeature(fromLinkFeature.LINK_ENTITY_KEY, fromLinkFeature.reducer),
	],
	providers: [LinkFacade],
})
export class DataAccessLinkModule {}
