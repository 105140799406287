/// <reference types="@types/google.maps" />

import { Inject, Injectable } from '@angular/core';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import { Address, AnyObject, MapConfig, Realty, Resource } from '@oper-client/shared/data-model';
import { Observable, Subject } from 'rxjs';
import { GoogleLibrariesService } from './google-libraries.service';
import { roundNumber } from '@oper-client/shared/util-formatting';

interface GoogleGeocodeAddressComponent {
	long_name: string;
	short_name: string;
	types: string[];
}

const autoCompleteAddressRealtyFields: (keyof Pick<Address, 'street' | 'houseNumber' | 'zipCode' | 'city' | 'country' | 'mapConfig'>)[] = [
	'street',
	'houseNumber',
	'zipCode',
	'city',
	'country',
	'mapConfig'
];

const addressStringFields: (keyof Pick<Address, 'street' | 'houseNumber' | 'zipCode' | 'city' | 'country'>)[] = [
	'houseNumber',
	'street',
	'city',
	'zipCode',
	'country',
];

@Injectable({
	providedIn: 'root',
})
export class GoogleGeocodeService {
	private placesService: google.maps.places.PlacesService;

	constructor(
		@Inject(CUSTOMER_INSIGHTS_CONFIG) public customerConfig: CustomerInsights,
		private googleLibrariesService: GoogleLibrariesService
	) {
		this.placesService = this.googleLibrariesService.placesService;
	}

	public googleAddressComponentsToRealty(
		addressComponents: GoogleGeocodeAddressComponent[],
		countryResources: Resource[],
		realtyData?: Partial<Realty>
	): Partial<Realty> {
		return addressComponents.reduce(
			(acc, item) => {
				if (item.types.includes('street_number')) {
					return { ...acc, address: { ...acc.address, houseNumber: item.long_name } };
				} else if (item.types.includes('route')) {
					return { ...acc, address: { ...acc.address, street: item.long_name } };
				} else if (item.types.includes('postal_code')) {
					return { ...acc, address: { ...acc.address, zipCode: item.long_name } };
				} else if (item.types.includes('locality')) {
					return {
						...acc,
						address: { ...acc.address, city: item.long_name },
					};
				} else if (item.types.includes('country')) {
					const countryResource = countryResources.find((country) => country.definition === item.short_name);
					return {
						...acc,
						address: {
							...acc.address,
							country: { id: countryResource.id },
						},
					};
				}
				return acc;
			},
			{ ...realtyData }
		);
	}

	public googleAddressComponentsToAddressForm(
		addressComponents: GoogleGeocodeAddressComponent[],
		countryResources: Resource[],
		realtyData?: Partial<Realty>,
		prefix = '',
		mapConfig?: MapConfig
	): any {
		const addressFormValue = {};
		const realty: Partial<Realty> = this.googleAddressComponentsToRealty(addressComponents, countryResources, realtyData);
		if (realty?.address) {
			autoCompleteAddressRealtyFields.forEach((field) => {
				if (typeof realty?.address[field] !== 'undefined') {
					if (field === 'country') {
						addressFormValue[`${prefix}${field}.id`] = realty.address.country?.id || '';
					} else {
						addressFormValue[`${prefix}${field}`] = realty.address[field];
					}
				}

				if (field === 'mapConfig' && mapConfig) {
					addressFormValue[`${prefix}${field}`] = mapConfig;
				}
			});
		}
		return addressFormValue;
	}

	addressFormToAddressString(
		addressFormValue: {
			[key: string]: any;
		},
		countryResources: Resource[],
	): string {
		let str = '';

		addressStringFields.forEach((field) => {
			let value: string | number;
			if (field === 'country') {
				value = addressFormValue[`address.country.id`];
			} else {
				value = addressFormValue[`address.${field}`];
			}
			if (value) {
				if (field === 'country') {
					str += ` ${countryResources?.length? countryResources.find((country) => country.id === value).definition : ''}`;
				} else {
					str += ` ${value},`;
				}
			}
		});

		return str;
	}

	getCoordinates(address: string): Observable<{ lat: number; lng: number }> {
		const subject = new Subject<{ lat: number; lng: number }>();

		this.placesService.findPlaceFromQuery(
			{
				query: address,
				fields: ['geometry']
			},
			(results, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK && results?.[0]?.geometry) {
					const location = results[0].geometry.location;
					subject.next({
						lat: roundNumber(location.lat(), 10),
						lng: roundNumber(location.lng(), 10),
					});
				} else {
					subject.next(null);
				}
				subject.complete();
			}
		);

		return subject.asObservable();
	}

	equalFormAddressData(address1: AnyObject<any>, address2: AnyObject<any>): boolean {
		return addressStringFields.every((field) => {
			return address1?.[`address.${field}${field === 'country'?'.id': ''}`] === address2?.[`address.${field}${field === 'country'?'.id': ''}`];
		});
	}
	
	equalMapConfigs(config1: MapConfig, config2: MapConfig): boolean {
		if(config1?.pov) {
			return JSON.stringify(config1) === JSON.stringify(config2);
		}

		return JSON.stringify(config1?.coordinates) === JSON.stringify(config2?.coordinates);
		
	}
}
