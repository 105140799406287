import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ApplicationFlowFacade } from './+state/application-flow.facade';
import { ApplicationFlowEffects } from './+state/application-flow.effects';
import * as applicationFlowReducer from './+state/application-flow.reducer';

import { LoanApplicationFacade } from './+state/loan-application/loan-application.facade';
import { LoanApplicationEffects } from './+state/loan-application/loan-application.effects';
import * as loanApplicationReducer from './+state/loan-application/loan-application.reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(applicationFlowReducer.APPLICATION_FLOW_KEY, applicationFlowReducer.reducer),
		StoreModule.forFeature(loanApplicationReducer.LOAN_APPLICATION_KEY, loanApplicationReducer.reducer),
		EffectsModule.forFeature([ApplicationFlowEffects, LoanApplicationEffects]),
	],
	providers: [ApplicationFlowFacade, LoanApplicationFacade],
})
export class DataAccessApplicationFlowModule {}
