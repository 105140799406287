import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Link } from '@oper-client/shared/data-model';
import { ILinkService, LINK_SERVICE } from '@oper-client/shared/data-access';

import * as LinkActions from './link.actions';

@Injectable()
export class LinkEffects {
	loadClients$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LinkActions.loadLinks),
			mergeMap(() =>
				this.linkService.getAll().pipe(
					map((links: Link[]) => LinkActions.loadLinksSuccess({ links })),
					catchError((error) => of(LinkActions.loadLinksFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		@Inject(LINK_SERVICE) private linkService: ILinkService
	) {}
}
