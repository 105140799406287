import { Injectable } from '@angular/core';
import {
	Step,
	StepValidationResult,
	StepNameValidator,
	StepHasNextValidator,
	StepNextStepValidator,
	StepConfigValidator,
} from '@oper-client/shared/data-model';

@Injectable({
	providedIn: 'root',
})
export class ApplicationFlowConfigValidatorService {
	validate(steps: Step[], skipFirst = false): StepValidationResult {
		const validators = [new StepNameValidator(), new StepHasNextValidator(), new StepNextStepValidator()];
		const stepsToValidate = skipFirst ? steps.slice(1) : steps;
		return this.applyValidators(stepsToValidate, validators);
	}

	private applyValidators(steps: Step[], validators: StepConfigValidator[]): StepValidationResult {
		const result = new StepValidationResult();
		validators.forEach((validator) => {
			const validatorResult = validator.validate(steps);
			if (validatorResult.length) {
				result.valid = false;
				result.errors.push(...validatorResult);
			}
		});
		result.errors.sort((a, b) => a.index - b.index);
		return result;
	}
}
