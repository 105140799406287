import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights, APP_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { StepNames } from '@oper-client/shared/data-model';
import { triodosRealtyDetailedStepConfig } from './flow-step-configurations/triodos-realty-detailed-step-config';
import { FlowStepConfig } from './interfaces/flow-step-config';

@Injectable({
	providedIn: 'root',
})
export class FlowStepConfigurationService {
	private readonly customer: string;
	private readonly customerSeparator: string = '-';

	private readonly flowStepConfig: Record<string, Record<string, any>> = {
		default: {
			[StepNames.REALTY_ADVANCED]: null,
		},
		triodos: {
			[StepNames.REALTY_ADVANCED]: triodosRealtyDetailedStepConfig,
		},
	};

	constructor(@Inject(APP_INSIGHTS_CONFIG) private applicationInsights: ApplicationInsights) {
		this.customer = this.getCustomer();
	}

	public getFlowStepConfig(step: StepNames): (data: any) => FlowStepConfig {
		return this.flowStepConfig?.[this.customer]?.[step] ?? this.flowStepConfig.default[step];
	}

	private getCustomer(): string | null {
		const name = this.applicationInsights?.name;
		return !name.includes(this.customerSeparator) || name.split(this.customerSeparator).length === 0
			? null
			: name.split(this.customerSeparator)[0];
	}
}
