import { Component, OnDestroy } from '@angular/core';
import { Observable, combineLatest, map, takeUntil } from 'rxjs';

import { Link } from '@oper-client/shared/data-model';
import { LanguageService } from '@oper-client/shared/util-language';
import { DestroyableComponent } from '@shared/util-component';
import { LinkFacade } from '@oper-client/shared/link/data-access-link';

@Component({
	selector: 'oper-client-links',
	styleUrl: './links.component.scss',
	template: `
		@for (link of links$ | async; track $index) {
			<a class="link" [href]="link.url" target="_blank" rel="noopener noreferrer nofollow">{{ link.displayText }}</a>
		}
	`,
})
export class LinksComponent extends DestroyableComponent implements OnDestroy {
	readonly linkData$: Observable<Link[]> = this.linkFacade.links$.pipe(takeUntil(this.destroy$));

	links$ = combineLatest([this.languageService.languageChanged$, this.linkData$]).pipe(
		map(([language, links]) => links.filter((link) => link.language.definition === language)),
		takeUntil(this.destroy$)
	);

	constructor(
		private readonly languageService: LanguageService,
		private readonly linkFacade: LinkFacade
	) {
		super();
	}
}
