import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown, faGlobe, IconDefinition } from '@oper-client/shared/util-fontawesome';
import { ContextMenuItem, Resource } from '@oper-client/shared/data-model';
import { LanguageService } from '@oper-client/shared/util-language';
import { orderBy, SortDirection } from '@oper-client/shared/util-array';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'oper-client-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
	@Input() set languages(languages: Array<Resource>) {
		this._languages = languages;

		if (Array.isArray(languages)) {
			this.generateContextMenuItems(languages);
		}
	}

	@Input() inverted: boolean;
	@Input() isMobile = false;
	@Input() skipPositionList: boolean;
	@Input() collapsed = false;
	@Input() isTransparent = false;
	@Input() isSortFormat = false;
	@Input() isGray = false;
	@Input() showIcon = true;
	@Input() highlight = false;
	@Output() languageChanged = new EventEmitter<string>();

	readonly iconExpand: IconDefinition = faChevronDown;
	readonly languageIcon: IconDefinition = faGlobe;
	expanded: boolean;
	items: ContextMenuItem[] = [];

	private _languages: Resource[];

	constructor(
		private languageService: LanguageService,
		private translateService: TranslateService
	) {}

	toggleExpanded(): void {
		this.expanded = !this.expanded;
	}

	changeLanguage(lang: ContextMenuItem): void {
		this.languageService.changeLanguage(lang.name, this._languages);
		this.languageChanged.emit(lang.name);
	}

	getCurrentLanguage(): string {
		return this.languageService.getCurrentLanguage();
	}

	generateContextMenuItems(languages: Resource[]): void {
		// TODO: Update the label with the lang.key, after the realease and resource updates
		this.items = orderBy(
			languages.map((lang) => ({
				name: lang.definition,
				label: this.translateService.instant(`ç.resource.language-name.${lang.definition}`),
			})),
			'label',
			SortDirection.ASC
		);
	}
}
